<template>
    <tr ref="rowStart" v-if="tableHeaders">
        <td
            @click="rowAction()"
            v-for="(tableCell, index_cell) in Object.values(row)"
            :key="index_cell"
            :class="{ expanded: isExpanded, selected: isSelected }"
        >
            <div
                class="dataCell"
                :class="{
                    iconCell:
                        tableHeaders[index_cell] &&
                        tableHeaders[index_cell].value == 'classification',
                }"
                :style="{
                    height: isExpanded
                        ? Math.max(Math.round(longestFieldLength / 35) * 20, 100) + 'px'
                        : '40px',
                }"
            >
                <span v-if="typeof tableCell === 'number'">
                    {{ tableCell }}
                </span>
                <!-- this part needs refactor! -->
                <span v-else-if="Array.isArray(tableCell)">
                    <v-tooltip top v-if="tableCell.join(',\r\n').length > 40" max-width="300">
                        <template v-slot:activator="{ on }">
                            <span v-on="on"> {{ tableCell.join(",\r\n") }}</span>
                        </template>
                        <span>
                            {{ tableCell.join(",\r\n") }}
                        </span>
                    </v-tooltip>
                    <span v-else>
                        {{ tableCell.join(",\r\n") }}
                    </span>
                </span>
                <v-tooltip top v-else-if="tableHeaders[index_cell].value == 'credentials.roles' && tableCell.length > 43" max-width="300">
                    <template v-slot:activator="{ on }">
                        <span v-on="on"> {{ tableCell.split("\\n").join("\n") }}</span>
                    </template>
                    <span>
                        {{ tableCell.split("\\n").join("\n")  }}
                    </span>
                </v-tooltip>
                <span v-else>
                    {{ tableCell ? tableCell.split("\\n").join("\n") : "" }}
                </span>
            </div>
        </td>
    </tr>
</template>

<script>
import TableElement from "./mixings/TableElement";

export default {
    props: ["isExpanded", "isSelected", "row", "tableHeaders", "userSelected"],
    mixins: [TableElement],
    name: "TableRow",
    mounted() {},
    watch: {
        isSelected: function (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$emit("selectElement", this.index);
            }
        },
        userSelected(value) {
            const email = value;
            for (let i in this.tableHeaders) {
                if (this.tableHeaders[i].value == "email") {
                    if (email && this.row[i] == email) {
                        this.$emit("selectElement", this.index);
                        return;
                    }
                }
            }
        },
    },
    computed: {
        longestFieldLength() {
            let max = 0;
            for (let cell in this.row) {
                if (max < String(this.row[cell]).length) {
                    max = String(this.row[cell]).length;
                }
            }
            return max;
        },
    },
    methods: {},
};
</script>

<style scoped>
.capitalHeader {
    text-transform: capitalize;
    text-align: left;
    padding: 5px;
    padding-left: 1.4em;
}

.dataCell {
    padding: 0.8em 0;
    text-align: left;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
    transition: height 0.5s;
    white-space: nowrap;
    cursor: pointer;
}

.iconCell {
    text-align: left;
    text-transform: capitalize;
    min-width: 7em;
}

td.expanded .dataCell {
    overflow: hidden;
    -webkit-line-clamp: 20; /* number of lines to show */
    height: 900px;
}

td.expanded {
    background: rgba(116, 204, 149, 0.25);
}
</style>
