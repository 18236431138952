<template>
    <v-card elevation="2" class="inputs-card">
        <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="4">
                <v-container>
                    <v-checkbox
                        v-model="addCredentialToUser"
                        :input-value="addCredentialToUser"
                        color="primary"
                        label="Add additional credentials for existing user"
                    />
                </v-container>
            </v-col>
            <v-col cols="4">
                <v-container>
                    <v-autocomplete
                        label="User email"
                        :items="users"
                        v-model="selectedEmail"
                        :disabled="!addCredentialToUser"
                    ></v-autocomplete>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <div class="input-container">
                <v-row ref="componentCard">
                    <v-col cols="auto" class="ma-0">
                        <v-checkbox
                            v-model="isExpiryDateAvailable"
                            :input-value="isExpiryDateAvailable"
                            color="primary"
                            label="Set expiry date and notify user on:"
                        />
                    </v-col>

                    <ApiInput
                        v-for="value in inputsEntries"
                        :key="value.name"
                        :title="value.name"
                        :label="value.label"
                        :type="value.type"
                        :default-value="value.value"
                        :items="value.items"
                        :mandatory="value.mandatory"
                        :version="inputsController.inputs.apiVersion.value"
                        :allowed-versions="value.versions"
                        :colSize="value.colSize ? value.colSize : 2"
                        :visible="value.visible"
                        :disabled="value.disabled"
                        :value="value.value"
                        :minimumPossibleDate="value.minimumPossibleDate"
                        :error="value.error"
                        :rules="value.rules"
                        @apiInputChange="apiInputChange"
                    ></ApiInput>
                </v-row>
            </div>
        </v-row>
        <v-row>
            <v-col cols="auto">
                <v-dialog
                    persistent
                    transition="dialog-top-transition"
                    max-width="600"
                    v-model="this.dialogs[this.currentDialog].show"
                >
                    <template>
                        <v-card>
                            <v-card-text>
                                <div class="text-h5 pa-5">{{ dialogs[currentDialog].text }}</div>
                                <div class="text-h7 px-6 py-4" v-if="currentDialog == 'userCreated'">
                                    ID: {{ userCreated.id }}
                                </div>
                                <div class="text-h7 px-6 py-5" v-if="currentDialog == 'userCreated'">
                                    Password: {{ userCreated.password.trim() }}
                                </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn color="primary" text @click="affirmativeCloseDialog()"
                                    >{{ currentDialog === 'createUser' ? 'Yes' : 'OK' }}
                                </v-btn>
                                <v-btn
                                    v-if="currentDialog == 'createUser'"
                                    color="error"
                                    text
                                    @click="negativeCloseDialog()"
                                    >No
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import ApiInput from "../../components/Layout/apiInput/ApiInput";
import Roles from "../../api/roles.js";
import ResponseConfig from "./addUsersResponseConfig";
import UsersResponseConfig from "./allUsersResponseConfig.js";
import { mapGetters } from "vuex";
import InputComponent from "../mixings/InputComponent.js";
import Api from "../../api/api.js";
import convertDateToText from "@/utilities/convertDateToText";
import getTomorrowDate from "@/utilities/getTomorrowDate";

export default {
    name: "AddUsersInputsComponent",
    components: {
        ApiInput,
    },
    mixins: [InputComponent],
    data() {
        return {
            inputsController: {
                inputs: {
                    expiryDate: {
                        name: "expiryDate",
                        label: "Expiry Date",
                        selected: false,
                        colSize: 3,
                        value: null,
                        type: "date",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: true,
                        minimumPossibleDate: getTomorrowDate(),
                        error: null,
                    },
                    apiVersion: {
                        name: "apiVersion",
                        label: "Api Version",
                        selected: true,
                        value: "V1",
                        type: "select",
                        items: ["V2", "V3"],
                        mandatory: false,
                        versions: ["V1"],
                        visible: false,
                        disabled: true,
                    },
                    userId: {
                        name: "userId",
                        label: "User Id",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    email: {
                        name: "email",
                        label: "Email",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                        rules: [value => !!value || 'Field is required']
                    },
                    username: {
                        name: "username",
                        label: "Username",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    quota: {
                        name: "quota",
                        label: "Quota",
                        selected: false,
                        colSize: 1,
                        value: "",
                        type: "number_input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        rules: [value => value !== "" || 'Field is required', value => value >= 0 || 'Quota can not be negative!']
                    },
                    quotaPeriod: {
                        name: "quotaPeriod",
                        label: "Quota Period",
                        selected: false,
                        colSize: 1,
                        value: "DAYS",
                        type: "dropdown",
                        items: ["DAYS", "MONTHS"],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                    },
                    company: {
                        name: "company",
                        label: "Company",
                        selected: false,
                        colSize: 3,
                        value: "Internal",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                        rules: [value => !!value || 'Field is required']
                    },
                    requestDate: {
                        name: "requestDate",
                        label: "Request Date",
                        selected: false,
                        colSize: 3,
                        value: convertDateToText(Date.now()),
                        type: "date",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        rules: [value => !!value || 'Field is required']
                    },
                    externalId: {
                        name: "externalId",
                        label: "External Id",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    roles: {
                        name: "roles",
                        label: "Roles",
                        selected: false,
                        colSize: 3,
                        value: [],
                        type: "multiselect-no-input",
                        items: Roles,
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        rules: [value => value.length > 0 || 'Field is required']
                    },
                    phone: {
                        name: "phone",
                        label: "Phone",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                        error: null,
                    },
                    geoTabId: {
                        name: "geoTabId",
                        label: "Geotab ID",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    address: {
                        name: "address",
                        label: "Address",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    reseller: {
                        name: "reseller",
                        label: "Reseller",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        disableIfAddingCredential: true,
                    },
                    notes: {
                        name: "notes",
                        label: "Notes",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                    },
                    isSendClientSecretAsSMS: {
                        name: "isSendClientSecretAsSMS",
                        label: "Send client secret as SMS",
                        selected: false,
                        colSize: 4,
                        value: false,
                        type: "checkbox",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: true,
                    },
                },
            },
            userCreated: {
                id: "",
                password: "",
            },
            currentDialog: "createUser",
            dialogs: {
                userCreated: {
                    show: false,
                    text: "The user has been created.",
                },
                createUser: {
                    show: false,
                    text: "Do you want to create the user?",
                },
            },
            users: [],
            emailUsernames: {},
            selectedEmail: "",
            addCredentialToUser: false,
            isExpiryDateAvailable: false,
            apiVersionPath: "/addUser",
            usersEndpoint: "/getUsers",
            apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
        };
    },
    mounted() {
        this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath;
    },
    activated() {
        this.getAllUsers();
    },
    watch: {
        "inputsController.inputs.phone.value"(newValue) {
            this.inputsController.inputs.isSendClientSecretAsSMS.disabled = !newValue;

            if (newValue) {
                return;
            }

            this.inputsController.inputs.isSendClientSecretAsSMS.value = false;
        },
        isExpiryDateAvailable(value) {
            this.inputsController.inputs.expiryDate.disabled = !value;
            this.inputsController.inputs.expiryDate.value = null;
            this.inputsController.inputs.expiryDate.error = null;
            this.inputsController.inputs.expiryDate.mandatory = false;

            if (!value) {
                return;
            }

            const requestDate = this.inputsController.inputs.requestDate.value;
            const originalDate = new Date(requestDate ? requestDate : Date.now());

            const expiryDate = originalDate.setDate(originalDate.getDate() + 30);
            this.inputsController.inputs.expiryDate.value = convertDateToText(expiryDate);
            this.inputsController.inputs.expiryDate.mandatory = true;
        },
        apiVersionPath(value) {
            this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + value;
        },
        addCredentialToUser(value) {
            for (let i in this.inputsController.inputs) {
                let input = this.inputsController.inputs[i];
                if (input.disableIfAddingCredential) {
                    input.disabled = value;
                }
            }
            if (!value) {
                for (let i in this.inputsController.inputs) {
                    let input = this.inputsController.inputs[i];
                    if (input.disableIfAddingCredential) {
                        input.value = "";
                    }
                }
            } else {
                this.inputsController.inputs.email.value = this.selectedEmail;
                let selectedUser = this.emailUsernames[this.selectedEmail];

                for (let i in selectedUser) {
                    this.inputsController.inputs[i].value = selectedUser[i];
                }
            }
        },
        selectedEmail(value) {
            this.inputsController.inputs.email.value = value;
            let selectedUser = this.emailUsernames[this.selectedEmail];

            if (selectedUser) {
                for (let i in selectedUser) {
                    let input = this.inputsController.inputs[i];
                    if (input) {
                        input.value = selectedUser[i];
                    }
                }
            } else {
                for (let i in this.inputsController.inputs) {
                    let input = this.inputsController.inputs[i];
                    if (input.disableIfAddingCredential) {
                        input.value = "";
                    }
                }
            }
        },
        "inputsController.inputs": {
            handler: function (newValue) {
                const isError = !!Object.values(newValue).find((item) => item.error);
                this.enableRequest(!isError);
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["getLoggedUser", "getLoginStatus", "getSessionToken"]),
        inputsEntries() {
            return Object.values(this.inputsController.inputs);
        },
    },

    methods: {
        affirmativeCloseDialog() {
            this.dialogs[this.currentDialog].show = false;
            const element = this.$refs.componentCard;
            const top = element.offsetTop;
            window.scrollTo(0, top);
            if (this.currentDialog == "createUser") {
                this.addUserRequest();
            } else if (this.currentDialog == "userCreated") {
                this.$emit("userSelected", this.inputsController.inputs.email.value);
            }
        },
        negativeCloseDialog() {
            this.dialogs[this.currentDialog].show = false;
            const element = this.$refs.componentCard;
            const top = element.offsetTop;
            window.scrollTo(0, top);
            this.$emit("unsetLoadingStatus");
        },
        apiInputChange(title, selected, value) {
            this.inputsController.inputs[title].selected = selected;
            this.inputsController.inputs[title].value = value;
        },
        executeRequest() {
            this.currentDialog = "createUser";
            this.dialogs[this.currentDialog].show = true;
        },
        addUserRequest() {
            let responseParsed = {
                error: false,
                errorMessage: "",
                responseTable: {},
                isSelectable: false,
                redirect: false,
            };

            if (this.isDemo) {
                responseParsed.responseTable = this.parseResponse(
                    JSON.parse(Api.mockedDTCRequest().body),
                    ResponseConfig
                );
                this.$emit("responseReady", responseParsed);
            } else {
                const expiryDate = this.inputsController.inputs.expiryDate.value;
                let body = {
                    email: this.inputsController.inputs.email.value,
                    roles: this.inputsController.inputs.roles.value,
                    quota: this.inputsController.inputs.quota.value,
                    quotaPeriod: this.inputsController.inputs.quotaPeriod.value,
                    geoTabId: this.inputsController.inputs.geoTabId.value,
                    date: this.inputsController.inputs.requestDate.value,
                    notes: this.inputsController.inputs.notes.value,
                    expiryDate: expiryDate ? new Date(expiryDate).toISOString() : null,
                    isExpiryDateAvailable: this.isExpiryDateAvailable,
                    isSendClientSecretAsSMS:
                        this.inputsController.inputs.isSendClientSecretAsSMS.value,
                };

                if (!this.addCredentialToUser) {
                    body["externalId"] = this.inputsController.inputs.externalId.value;
                    body["company"] = this.inputsController.inputs.company.value;
                    body["address"] = this.inputsController.inputs.address.value;
                    body["reseller"] = this.inputsController.inputs.reseller.value;
                    body["phone"] = this.inputsController.inputs.phone.value;
                } else {
                    body["userId"] =
                        this.emailUsernames[this.inputsController.inputs.email.value].userId;
                }
                fetch(this.apiEndpoint, {
                    mode: "cors",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.getSessionToken,
                    },
                    body: JSON.stringify(body),
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            response.status = 500;
                            response.errorMessage = "Unknown error";
                            this.$emit("unsetLoadingStatus");
                            return response;
                        }
                    })
                    .then((data) => {
                        if (data) {
                            if (data.statusCode == 500) {
                                responseParsed.status = 500;
                                responseParsed.error = true;
                                responseParsed.errorMessage = JSON.parse(data.body).description;
                            } else if (data.statusCode == 400) {
                                responseParsed.status = 500;
                                responseParsed.error = true;
                                responseParsed.errorMessage = JSON.parse(data.body).description;
                            } else if (data.statusCode == 200) {
                                this.$emit("changeAction", "List Users");
                                this.currentDialog = "userCreated";
                                this.dialogs[this.currentDialog].show = true;
                                let body = JSON.parse(data.body);
                                this.userCreated.id = body.username;
                                this.userCreated.password = body.password;
                            }
                            this.$emit("responseReady", responseParsed);
                        }
                    })
                    .catch((error) => {
                        responseParsed.redirect = false;
                        responseParsed.error = true;
                        responseParsed.status = 500;
                        responseParsed.errorMessage =
                            "There has been a problem with your fetch operation: " +
                            error +
                            ". This error migth be caused because you do not have a valid session active or your IP is being whitelisted.";
                        console.error("There has been a problem with your fetch operation:", error);
                        this.$emit("unsetLoadingStatus");

                        this.$emit("responseReady", responseParsed);
                    });
            }
        },
        async getAllUsers() {
            this.$emit("setLoadingStatus", true);

            let responseParsed = {
                error: false,
                errorMessage: "",
                responseTable: {},
                isSelectable: true,
                redirect: false,
            };

            let body = JSON.stringify({
                body: {},
                sort: { email: 1 },
            });

            let token = await this.getSessionToken;

            if (!token) {
                token = await this.$store.dispatch("getLoggedInfo");
            }

            fetch(process.env.VUE_APP_DATA_SERVICES_API_URL + this.usersEndpoint, {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: body,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        response.status = 500;
                        response.errorMessage = "Unknown error";
                        return response;
                    }
                })
                .then((data) => {
                    if (data) {
                        if (data.statusCode == 500) {
                            responseParsed.status = 500;
                            responseParsed.error = true;
                            responseParsed.errorMessage = JSON.parse(data.body).description;
                        } else if (data.statusCode == 501) {
                            responseParsed.error = true;
                            responseParsed.errorMessage = data.body;
                        } else if (data.statusCode == 200) {
                            let parsedAnswer = JSON.parse(data.body);
                            let tableResponse = [];

                            if (this.apiEndpoint == this.getUserPath) {
                                tableResponse.push(parsedAnswer);
                            } else {
                                tableResponse = parsedAnswer;
                            }

                            responseParsed.responseTable = this.parseResponse(
                                tableResponse,
                                UsersResponseConfig
                            );

                            for (let row of responseParsed.responseTable.tableContent) {
                                this.users.push(row.email);

                                this.emailUsernames[row.email] = {
                                    userId: row.userId,
                                    company: row.company,
                                    address: row.address,
                                    phone: row.phone,
                                    reseller: row.reseller,
                                    externalId: row.externalId,
                                };
                            }
                            this.$emit("unsetLoadingStatus", true);
                        } else {
                            responseParsed.error = true;
                            responseParsed.errorMessage = "Unknown error";
                        }
                    }
                })
                .catch((error) => {
                    responseParsed.error = false;
                    responseParsed.status = 500;
                    responseParsed.errorMessage =
                        "There has been a problem with your fetch operation: " +
                        error +
                        ". This error migth be caused because you do not have a valid session active or your IP is being whitelisted.";
                    responseParsed.redirect = true;
                    this.$emit("responseReady", responseParsed);
                });
        },
    },
};
</script>

<style scoped>
.input-container {
    margin-left: 1em;
    margin-rigth: 1em;
    margin-top: 1em;
}

.inputs-card {
    margin-left: 1em;
    margin-right: 1em;
    width: 95em;
}
</style>
