<template>
    <div class="content">
        <keep-alive>
            <AddUsersInputsComponent
                v-if="actionType == 'Add User'"
                ref="Add User"
                :isDemo="isDemo"
                @enableRequest="enableRequest"
                @responseReady="responseReady"
                @unsetLoadingStatus="unsetLoadingStatus"
                @setLoadingStatus="setLoadingStatus"
                @changeAction="changeAction"
                @userSelected="userSelected"
            ></AddUsersInputsComponent>
            <ListUsersInputsComponent
                v-else-if="actionType == 'List Users'"
                :isDemo="isDemo"
                :dismissal-request="dismissalRequest"
                ref="List Users"
                @enableRequest="enableRequest"
                @responseReady="responseReady"
                @setLoadingStatus="setLoadingStatus"
                @hideInternal="hideInternal"
            ></ListUsersInputsComponent>
        </keep-alive>
    </div>
</template>
<script>
import AddUsersInputsComponent from "../../inputConfig/addUsers/addUsersInputsComponent";
import ListUsersInputsComponent from "../../inputConfig/listUsers/listUsersInputsComponent";

export default {
    props: ["actionType", "isDemo", "dismissalRequest"],
    data() {
        return {};
    },
    components: {
        AddUsersInputsComponent,
        ListUsersInputsComponent,
    },
    name: "ComponentRenderer",
    methods: {
        hideInternal(value) {
            this.$emit("hideInternal", value);
        },
        selectComponent(componentData) {
            this.$refs[this.actionType].selectComponent(componentData);
        },
        enableRequest(value) {
            this.$emit("enableRequest", value);
        },
        setLoadingStatus() {
            this.$emit("setLoadingStatus");
        },
        unsetLoadingStatus() {
            this.$emit("unsetLoadingStatus");
        },
        executeRequest() {
            this.$refs[this.actionType].executeRequest();
        },

        responseReady(response) {
            this.$emit("responseReady", response);
        },
        setButtonLabel(label) {
            this.$emit("setButtonLabel", label);
        },
        changeAction(action) {
            this.$emit("changeAction", action);
        },
        userSelected(user) {
            this.$emit("userSelected", user);
        },
    },
};
</script>

<style scoped>
.content {
    padding-top: 50px;
    display: flex;
    justify-content: center;
}
</style>
