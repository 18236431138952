import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue';
import UserManagement from "../pages/UserManagement";

Vue.use(VueRouter);
const routes = [

    {
        path: "/",
        name: "Login",
        component: Login

    },
    {
        path: "/userManagement",
        name: "UserManagement",
        component: UserManagement,
        props:  { actionType: 'List Users'},
    },
]


const router = new VueRouter({
    mode: 'history',
    routes
});


export default router
