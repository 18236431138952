<template>
    <v-card elevation="2" class="inputs-card">
        <div class="input-container" ref="inputContainer">
            <v-container>
                <v-row justify="space-around">
                    <v-checkbox
                        v-model="hideInternal"
                        :input-value="hideInternal"
                        color="primary"
                        label="Hide internal Users"
                    />
                    <v-checkbox
                        v-model="isNotDisplayUsersWithZeroQuota"
                        :input-value="isNotDisplayUsersWithZeroQuota"
                        color="primary"
                        label="Hide inactive users"
                    />
                </v-row>
            </v-container>

            <FilterInputs
                @handelFilterValuer="handelFilterValuer"
                @handelClearInput="executeRequest"
                @onFilter="executeRequest"
            />

            <v-row v-if="isComponentSelected">
                <v-col cols="2"></v-col>
                <v-col cols="8">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5"> User data</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

            <v-row ref="componentCard" v-if="isComponentSelected">
                <v-col>
                    <v-card class="mx-auto" max-width="1000">
                        <v-row>
                            <v-col cols="8">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                            {{ selectedComponent.email.value }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <v-list class="transparent column_wrapper" v-if="!edit_mode">
                            <v-list-item v-for="(item, key) in selectedValues" :key="key">
                                <v-col cols="4">
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-col>
                                <v-col cols="8">
                                    <v-list-item-subtitle
                                        v-if="item.name === 'credentials.geoTabId'"
                                        class="text-right"
                                    >
                                        {{ item.value.length > 10 ? "..." + item.value.slice(-4) : item.value }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else-if="!item.type" class="text-right">
                                        {{ item.value }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-else-if="item.type == 'date'"
                                        class="text-right"
                                    >
                                        {{ item.value.split("T")[0] }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else-if="item.type == 'list'">
                                        <div
                                            v-for="(value, name) in item.value.split(', ')"
                                            :key="name"
                                            class="sublistSelectedItem"
                                        >
                                            {{ value }}
                                        </div>
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-list-item>
                        </v-list>

                        <v-list class="transparent" v-else>
                            <ApiInput
                                v-for="value in userEntries"
                                :key="value.name"
                                :title="value.name"
                                :label="value.label"
                                :type="value.type"
                                :default-value="value.value"
                                :items="value.items"
                                :mandatory="value.mandatory"
                                :validateOnMount="true"
                                :version="inputsController.inputs.apiVersion.value"
                                :allowed-versions="value.versions"
                                :visible="value.visible"
                                :colSize="10"
                                :rules="value.rules"
                                :disabled="value.disabled || isDemo"
                                :minimumPossibleDate="value.minimumPossibleDate"
                                :error="value.error"
                                @apiInputChange="apiInputChange"
                            ></ApiInput>
                        </v-list>
                        <v-card-actions>
                            <v-btn v-if="!edit_mode" text color="primary" @click="toggleEditMode">
                                Modify User
                            </v-btn>
                            <v-btn
                                v-if="!edit_mode"
                                text
                                color="alertBlue"
                                @click="dismissComponentSelection"
                            >
                                Dismiss
                            </v-btn>
                            <v-btn
                                v-if="edit_mode"
                                text
                                color="primary"
                                @click="saveUser"
                                :disabled="!enableSaveUser"
                            >
                                Save User
                            </v-btn>
                            <v-btn
                                v-if="!edit_mode && credentials.length == 0"
                                text
                                color="alertBlue"
                                @click="updatePassword"
                            >
                                Update Password
                            </v-btn>
                            <v-btn v-if="edit_mode" text color="error" @click="toggleEditMode">
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-row v-if="isCredentialSelected">
                <v-col cols="2"></v-col>
                <v-col cols="8">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">
                                Selected Credential
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row class="credential-section" ref="credentialCard" v-if="isCredentialSelected">
                <v-col>
                    <v-card class="mx-auto" max-width="1000">
                        <v-row>
                            <v-col cols="8">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                            {{ selectedCredential.username.value }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <v-list class="transparent column_wrapper" v-if="!edit_credential_mode">
                            <v-list-item v-for="(item, key) in selectedCredentialValues" :key="key">
                                <v-col cols="4">
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-col>
                                <v-col cols="8">
                                    <v-list-item-subtitle v-if="!item.type" class="text-right">
                                        {{ item.value }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-else-if="item.type == 'date'"
                                        class="text-right"
                                    >
                                        {{ item.value.split("T")[0] }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else-if="item.type == 'list'">
                                        <div
                                            v-for="(value, name) in item.value.split(', ')"
                                            :key="name"
                                            class="sublistSelectedItem"
                                        >
                                            {{ value }}
                                        </div>
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-list-item>
                        </v-list>

                        <v-list class="transparent" v-else>
                            <ApiInput
                                v-for="value in credentialsEntries"
                                :key="value.name"
                                :title="value.name"
                                :label="value.label"
                                :type="value.type"
                                :default-value="value.value"
                                :items="value.items"
                                :mandatory="value.mandatory"
                                :version="inputsController.inputs.apiVersion.value"
                                :allowed-versions="value.versions"
                                :visible="value.visible"
                                :colSize="10"
                                :disabled="value.disabled || isDemo"
                                :minimumPossibleDate="value.minimumPossibleDate"
                                @apiInputChange="apiInputChange"
                            ></ApiInput>
                        </v-list>
                        <v-card-actions>
                            <v-btn
                                v-if="!edit_credential_mode"
                                text
                                color="primary"
                                @click="toggleEditCredentialMode"
                            >
                                Modify Credential
                            </v-btn>
                            <v-btn
                                v-if="!edit_credential_mode"
                                text
                                color="alertBlue"
                                @click="dismissCredentialSelection"
                            >
                                Dismiss
                            </v-btn>
                            <v-btn
                                v-if="edit_credential_mode"
                                text
                                color="primary"
                                @click="saveCredential"
                                :disabled="!enableSaveCredential"
                            >
                                Save Credentials
                            </v-btn>
                            <v-btn
                                v-if="!edit_credential_mode"
                                text
                                color="alertBlue"
                                @click="updatePassword"
                            >
                                Update Password
                            </v-btn>
                            <v-btn
                                v-if="edit_credential_mode"
                                text
                                color="error"
                                @click="toggleEditCredentialMode"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-row v-if="credentials.length > 1">
                <v-col cols="8">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">
                                Credentials list
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row class="credential-section" v-if="credentials.length > 1">
                <v-col>
                    <v-card>
                        <v-row>
                            <v-col>
                                <Table
                                    :response-table="{
                                        tableHeaders: credentialsTableHeaders,
                                        tableContent: credentials,
                                    }"
                                    :is-credentials-table="true"
                                    :is-selectable="true"
                                    :hide-internal="false"
                                    @selectElement="selectCredential"
                                ></Table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-col cols="auto">
            <v-dialog
                persistent
                transition="dialog-top-transition"
                max-width="600"
                v-model="this.dialogs[this.currentDialog].show"
            >
                <template>
                    <v-card>
                        <div class="pa-7 pb-0">
                            <span class="text-h5">{{ dialogs[currentDialog].text }}</span>
                            <ApiInput
                                v-if="currentDialog === 'updatePasswordDialog'"
                                class="pa-0"
                                :title="inputsController.inputs.isSendClientSecretAsSMS.name"
                                :label="inputsController.inputs.isSendClientSecretAsSMS.label"
                                :type="inputsController.inputs.isSendClientSecretAsSMS.type"
                                :default-value="
                                    inputsController.inputs.isSendClientSecretAsSMS.value
                                "
                                :items="inputsController.inputs.isSendClientSecretAsSMS.items"
                                :mandatory="
                                    inputsController.inputs.isSendClientSecretAsSMS.mandatory
                                "
                                :version="inputsController.inputs.apiVersion.value"
                                :allowed-versions="
                                    inputsController.inputs.isSendClientSecretAsSMS.versions
                                "
                                :visible="inputsController.inputs.isSendClientSecretAsSMS.visible"
                                :colSize="10"
                                :disabled="
                                    inputsController.inputs.isSendClientSecretAsSMS.disabled ||
                                    isDemo
                                "
                                :error="inputsController.inputs.isSendClientSecretAsSMS.error"
                                @apiInputChange="apiInputChange"
                            />
                        </div>
                        <div v-if="currentDialog == 'passwordUpdated'">
                            <div class="text-h7 px-7 py-5" v-if="currentDialog == 'passwordUpdated'">
                                ID: {{ passwordUpdated.username }}
                            </div>
                            <div class="text-h7 px-7 py-5" v-if="currentDialog == 'passwordUpdated'">
                                Password: {{ passwordUpdated.password }}
                            </div>
                        </div>
                        <v-card-actions class="justify-end">
                            <v-btn color="primary" text @click="affirmativeCloseDialog()"
                                >{{ this.dialogs[this.currentDialog].noButton ? 'Yes' : 'OK' }}
                            </v-btn>
                            <v-btn
                                v-if="this.dialogs[this.currentDialog].noButton"
                                color="error"
                                text
                                @click="negativeCloseDialog()"
                                >No
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-card>
</template>
<script>
import ApiInput from "../../components/Layout/apiInput/ApiInput";
import ResponseConfig from "./listUsersResponseConfig";
import { mapGetters } from "vuex";
import InputComponent from "../mixings/InputComponent.js";
import loginModule from "@/store";
import Roles from "@/api/roles";
import Table from "@/components/Table/Table.vue";
import { UserFields, UserFieldsLabel, UserFieldsOptions } from "@/constants";
import FilterInputs from "@/inputConfig/listUsers/components/FilterInputs";
import getTomorrowDate from "@/utilities/getTomorrowDate";

export default {
    name: "ListUsersInputsComponent",
    components: {
        ApiInput,
        Table,
        FilterInputs,
    },
    loginModule,
    props: ["dismissalRequest"],
    mixins: [InputComponent],
    activated() {
        this.edit_mode = false;
        this.edit_credential_mode = false;
        this.enableRequest();
        this.executeRequest();
    },
    data() {
        return {
            saveUserPath: "/updateUser",
            apiVersionPath: "/getUsers",
            getUserPath: "/getUser",
            regExEmailFindPath: "/getUserByEmailWildcard",
            regUserIDFindPath: "/getUserByUserIDWildcard",
            apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
            isComponentSelected: false,
            edit_mode: false,
            edit_credential_mode: false,
            currentDialog: "saveDialog",
            hideInternal: true,
            isNotDisplayUsersWithZeroQuota: true,
            isCredentialSelected: false,
            passwordUpdated: {},
            dialogs: {
                saveDialog: {
                    show: false,
                    text: "You are going to save the changes. Do you want to proceed?",
                    noButton: true,
                },
                discardDialog: {
                    show: false,
                    text: "You are going to discard the changes. Do you want to proceed?",
                    functionCalledWhenDialogIsCalled: () => {},
                    noButton: true,
                },
                updatePasswordDialog: {
                    show: false,
                    text: "You are going to update the password. Do you want to proceed?",
                    noButton: true,
                },
                discardSaveCredentialsDialog: {
                    show: false,
                    text: "You are going to discard the changes on this credentials. Do you want to proceed?",
                    functionCalledWhenDialogIsCalled: () => {},
                    noButton: true,
                },
                saveCredential: {
                    show: false,
                    text: "You are going to save the changes in this credentials. Do you want to proceed?",
                    noButton: true,
                },
                passwordUpdated: {
                    show: false,
                    text: "The Password has been updated.",
                    noButton: false,
                },
            },
            selectedCredential: {
                isExpiryDateAvailable: {
                    name: UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE],
                    referenceToUser: "credentials.isExpiryDateAvailable",
                    value: "",
                    visible: false,
                },
                expiryDate: {
                    name: UserFields.CREDENTIALS_EXPIRY_DATE,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_EXPIRY_DATE],
                    referenceToUser: "credentials.expiryDate",
                    value: "",
                    type: "date",
                    visible: true,
                },
                roles: {
                    name: "roles",
                    label: "Roles",
                    referenceToUser: "credentials.roles",
                    value: "",
                    visible: true,
                    type: "list",
                },
                password: {
                    name: "password",
                    label: "Password",
                    referenceToUser: "credentials.password",
                    value: "",
                    visible: true,
                },
                username: {
                    name: "username",
                    label: "Username",
                    referenceToUser: "credentials.username",
                    value: "",
                    visible: true,
                },
                quotaInt: {
                    name: "quotaInt",
                    label: "Quota Int",
                    referenceToUser: "credentials.quotaInt",
                    value: "",
                    visible: true,
                },
                quota: {
                    name: "quota",
                    label: "Quota",
                    referenceToUser: "credentials.quota",
                    value: "",
                    visible: true,
                },
                geoTabId: {
                    name: "geoTabId",
                    label: "GeoTab ID",
                    referenceToUser: "credentials.geoTabId",
                    value: "",
                    visible: true,
                },
            },
            selectedComponent: {
                "credentials.isExpiryDateAvailable": {
                    name: UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE],
                    value: "",
                    visible: false,
                },
                "credentials.expiryDate": {
                    name: UserFields.CREDENTIALS_EXPIRY_DATE,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_EXPIRY_DATE],
                    value: "",
                    type: "date",
                    visible: true,
                },
                userId: {
                    name: UserFields.USER_ID,
                    label: UserFieldsLabel[UserFields.USER_ID],
                    value: "",
                    visible: true,
                },
                email: {
                    name: UserFields.EMAIL,
                    label: UserFieldsLabel[UserFields.EMAIL],
                    value: "",
                    visible: true,
                },
                "credentials.roles": {
                    name: UserFields.CREDENTIALS_ROLES,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_ROLES],
                    value: "",
                    type: "list",
                    visible: true,
                },
                "credentials.username": {
                    name: UserFields.CREDENTIALS_USERNAME,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_USERNAME],
                    value: "",
                    visible: true,
                },
                "credentials.password": {
                    name: UserFields.CREDENTIALS_PASSWORD,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_PASSWORD],
                    value: "",
                    type: "password",
                    visible: true,
                },
                "credentials.quotaInt": {
                    name: UserFields.CREDENTIALS_QUOTA_INT,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_QUOTA_INT],
                    value: "",
                    visible: true,
                },
                "credentials.quota": {
                    name: UserFields.CREDENTIALS_QUOTA,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_QUOTA],
                    value: "",
                    visible: true,
                },
                address: {
                    name: UserFields.ADDRESS,
                    label: UserFieldsLabel[UserFields.ADDRESS],
                    value: "",
                    visible: true,
                },
                company: {
                    name: UserFields.COMPANY,
                    label: UserFieldsLabel[UserFields.COMPANY],
                    value: "",
                    visible: true,
                },
                date: {
                    name: UserFields.DATE,
                    label: UserFieldsLabel[UserFields.DATE],
                    value: "",
                    type: "date",
                    visible: true,
                },
                phone: {
                    name: UserFields.PHONE,
                    label: UserFieldsLabel[UserFields.PHONE],
                    value: "",
                    visible: true,
                },
                reseller: {
                    name: UserFields.RESELLER,
                    label: UserFieldsLabel[UserFields.RESELLER],
                    value: "",
                    visible: true,
                },
                notes: {
                    name: UserFields.NOTES,
                    label: UserFieldsLabel[UserFields.NOTES],
                    value: "",
                    visible: true,
                },
                externalId: {
                    name: UserFields.EXTERNAL_ID,
                    label: UserFieldsLabel[UserFields.EXTERNAL_ID],
                    value: "",
                    visible: true,
                },
                "credentials.geoTabId": {
                    name: UserFields.CREDENTIALS_GEOTABID,
                    label: UserFieldsLabel[UserFields.CREDENTIALS_GEOTABID],
                    value: "",
                    visible: true,
                },
            },
            filterValues: {},
            inputsController: {
                inputs: {
                    apiVersion: {
                        name: "apiVersion",
                        label: "Api Version",
                        selected: true,
                        value: "V1",
                        type: "select",
                        items: ["V1"],
                        mandatory: false,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        isUserProperty: false,
                    },
                    email: {
                        name: "email",
                        label: "Email",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                        rules: [value => !!value || 'Field is required']
                    },
                    company: {
                        name: "company",
                        label: "Company",
                        selected: true,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        colSize: 3,
                        isUserProperty: true,
                        rules: [value => !!value || 'Field is required']
                    },
                    date: {
                        name: "date",
                        label: "Date",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "date",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                        rules: [value => !!value || 'Field is required']
                    },
                    userId: {
                        name: "userId",
                        label: "User ID",
                        colSize: 4,
                        selected: false,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: true,
                        isUserProperty: true,
                    },
                    "credentials.roles": {
                        name: "credentials.roles",
                        label: "Roles",
                        selected: false,
                        colSize: 3,
                        value: [],
                        type: "multiselect-no-input",
                        items: Roles,
                        mandatory: true,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                        rules: [value => value.length > 0 || 'Field is required']
                    },
                    "credentials.username": {
                        name: "credentials.username",
                        label: "Username",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: false,
                        disabled: true,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                    },
                    "credentials.password": {
                        name: "credentials.password",
                        label: "Password",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "password",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: false,
                        disabled: true,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                    },
                    "credentials.quota": {
                        name: "credentials.quota",
                        label: "Quota",
                        selected: false,
                        colSize: 3,
                        value: -1,
                        type: "input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: false,
                        disabled: true,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                    },
                    "credentials.quotaInt": {
                        name: "credentials.quotaInt",
                        label: "Quota Int",
                        selected: false,
                        colSize: 3,
                        value: -1,
                        type: "number_input",
                        items: [],
                        mandatory: true,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                        rules: [value => value !== "" || 'Field is required', value => value >= 0 || 'Quota can not be negative!']
                    },
                    "credentials.quotaPeriod": {
                        name: "credentials.quotaPeriod",
                        label: "Quota Period",
                        selected: false,
                        colSize: 3,
                        value: "DAYS",
                        type: "dropdown",
                        items: ["DAYS", "MONTHS"],
                        mandatory: true,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                    },
                    "credentials.geoTabId": {
                        name: "credentials.geoTabId",
                        label: "GeoTab ID",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: false,
                        disabled: false,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                    },
                    address: {
                        name: "address",
                        label: "Address",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                    },
                    phone: {
                        name: "phone",
                        label: "Phone",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                        error: null,
                    },
                    reseller: {
                        name: "reseller",
                        label: "Reseller",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                    },
                    notes: {
                        name: "notes",
                        label: "Notes",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                    },
                    externalId: {
                        name: "externalId",
                        label: "ExternalId",
                        selected: false,
                        colSize: 3,
                        value: "",
                        type: "input",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isUserProperty: true,
                    },
                    "credentials.isExpiryDateAvailable": {
                        name: "credentials.isExpiryDateAvailable",
                        label: "Set expiry date and notify user on",
                        selected: false,
                        colSize: 4,
                        value: null,
                        type: "checkbox",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: false,
                        isCredentialsProperty: true,
                        isUserProperty: true,
                    },
                    "credentials.expiryDate": {
                        name: "credentials.expiryDate",
                        label: "Expiry date",
                        selected: false,
                        colSize: 4,
                        value: "",
                        type: "date",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: true,
                        isUserProperty: true,
                        isCredentialsProperty: true,
                        minimumPossibleDate: getTomorrowDate(),
                        error: null,
                    },
                    isSendClientSecretAsSMS: {
                        name: "isSendClientSecretAsSMS",
                        label: "Send client secret as SMS",
                        selected: false,
                        colSize: 4,
                        value: false,
                        type: "checkbox",
                        items: [],
                        mandatory: false,
                        versions: ["V1"],
                        visible: true,
                        disabled: true,
                        isUserProperty: false,
                    },
                },
            },
            credentials: [],
            credentialsFields: [
                "credentials.roles",
                "credentials.username",
                "credentials.password",
                "credentials.quotaInt",
                "credentials.quota",
                "credentials.geoTabId",
                "credentials.expiryDate",
                "credentials.isExpiryDateAvailable"
            ],
            credentialsTableHeaders: [
                {
                    text: "Roles",
                    value: "roles",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                },
                {
                    text: "Username",
                    value: "username",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                },
                {
                    text: "Password",
                    value: "password",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                },
                {
                    text: "Quota",
                    value: "quotaInt",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                },
                {
                    text: "Quota",
                    value: "quota",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                },
                {
                    text: "GeoTab ID",
                    value: "geoTabId",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                    display: (value) => value && value.length > 10 ? "..." + value.slice(-4) : value
                },
                {
                    text: "Expiry Date",
                    value: "expiryDate",
                    visibleWhenMobile: true,
                    notVisibleWhenRow: false,
                    display: (value) => value && value.split("T")[0]
                },
            ],
        };
    },

    watch: {
        "selectedComponent.phone.value"(value) {
            this.inputsController.inputs.isSendClientSecretAsSMS.disabled = !value;

            if (value) {
                return;
            }

            this.inputsController.inputs.isSendClientSecretAsSMS.value = false;
        },
        dismissalRequest(value) {
            if (value) {
                this.dismissComponentSelection();
            }
        },
        hideInternal(value) {
            this.$emit("hideInternal", value);
        },
        isNotDisplayUsersWithZeroQuota() {
            this.executeRequest();
        },
    },
    computed: {
        ...mapGetters(["getLoggedUser", "getLoginStatus", "getSessionToken"]),
        userEntries() {
            let entries = [];
            for (let i in this.inputsController.inputs) {
                const entry = this.inputsController.inputs[i];
                if (entry.isUserProperty == true && entry.visible) {
                    entries.push(entry);
                }
            }
            return entries;
        },
        credentialsEntries() {
            let entries = [];
            for (let i in this.inputsController.inputs) {
                const entry = this.inputsController.inputs[i];
                if (entry.isCredentialsProperty == true && entry.visible) {
                    entries.push(entry);
                }
            }
            return entries;
        },

        selectedCredentialValues() {
            let entries = [];
            for (let i in this.selectedCredential) {
                const entry = this.selectedCredential[i];
                if (entry.type != "password" && entry.visible) {
                    entries.push(entry);
                }
            }
            return entries;
        },
        selectedValues() {
            let entries = [];
            for (let i in this.selectedComponent) {
                const entry = this.selectedComponent[i];
                if (entry.type != "password" && entry.visible) {
                    entries.push(entry);
                }
            }
            return entries;
        },
        enableSaveUser() {
            const arrayInputs = Object.values(this.inputsController.inputs);

            const isError = !!arrayInputs.find((i) => i.error);
            if (isError) {
                return false;
            }

            return !arrayInputs
                .filter((i) => i.isUserProperty && i.mandatory && i.visible)
                .find((i) => !this.isMandatoryFieldFilled(i));
        },
        enableSaveCredential() {
            let keys = Object.keys(this.inputsController.inputs);
            for (let i in keys) {
                let element = this.inputsController.inputs[keys[i]];
                if (element.isCredentialsProperty && element.mandatory) {
                    let fieldFilled = this.isMandatoryFieldFilled(element);
                    if (!fieldFilled) {
                        return false;
                    }
                }
            }
            return true;
        },
    },

    methods: {
        handelFilterValuer(newValue) {
            this.filterValues = newValue;
            this.enableRequest();
        },
        goToTopUserCard() {
            const element = this.$refs.inputContainer;
            const top = element.offsetTop;

            window.scrollTo(0, top);
        },
        goToTopCredentialCard(offset = 0) {
            let element = this.$refs.credentialCard;
            if (!element) {
                element = this.$refs.inputContainer;
            }
            const top = element.offsetTop + offset;
            window.scrollTo(0, top);
        },
        negativeCloseDialog() {
            this.dialogs[this.currentDialog].show = false;
            this.goToTopUserCard();
        },
        saveUser() {
            if (!this.enableSaveUser) {
                return;
            }
            this.currentDialog = "saveDialog";
            this.dialogs[this.currentDialog].show = true;
        },
        saveCredential() {
            this.currentDialog = "saveCredential";
            this.dialogs[this.currentDialog].show = true;
        },
        updatePassword() {
            this.currentDialog = "updatePasswordDialog";
            this.dialogs[this.currentDialog].show = true;
        },
        displayDiscardDialog(func, argument) {
            this.currentDialog = "discardDialog";
            this.dialogs[this.currentDialog].show = true;

            if (!func) {
                return;
            }

            this.dialogs[this.currentDialog].functionCalledWhenDialogIsCalled = this.createFunction(
                func,
                argument
            );
        },
        displayDiscardSaveCredentialsDialog(func, argument) {
            this.currentDialog = "discardSaveCredentialsDialog";
            this.dialogs[this.currentDialog].show = true;

            if (!func) {
                return;
            }

            this.dialogs[this.currentDialog].functionCalledWhenDialogIsCalled = this.createFunction(
                func,
                argument
            );
        },
        createFunction(func, argument) {
            return () => (argument ? func(argument) : func());
        },
        async affirmativeCloseDialog() {
            switch (this.currentDialog) {
                case "updatePasswordDialog":
                    await this.updatePasswordRequest();
                    this.edit_credential_mode = false;
                    this.goToTopCredentialCard();
                    break;
                case "saveDialog":
                    await this.saveUserRequest();
                    this.edit_mode = false;
                    this.goToTopUserCard();
                    break;
                case "discardDialog":
                    this.edit_mode = false;

                    this.goToTopUserCard();
                    this.dialogs[this.currentDialog].functionCalledWhenDialogIsCalled();

                    break;
                case "discardSaveCredentialsDialog":
                    this.edit_credential_mode = false;

                    this.goToTopCredentialCard();
                    this.dialogs[this.currentDialog].functionCalledWhenDialogIsCalled();

                    break;
                case "saveCredential":
                    this.edit_credential_mode = false;
                    await this.updateCredentialsRequest();
                    this.goToTopCredentialCard();
                    break;
                case "passwordUpdated":
                    this.goToTopCredentialCard();
                    this.passwordUpdated = {};
                    break;
            }

            this.dialogs[this.currentDialog].show = false;
        },
        toggleEditCredentialMode() {
            if (this.edit_mode) {
                return this.displayDiscardDialog();
            }

            if (this.edit_credential_mode) {
                return this.displayDiscardSaveCredentialsDialog();
            }

            this.edit_credential_mode = true;
            for (let i in this.selectedCredential) {
                let reference = this.selectedCredential[i].referenceToUser;
                if (
                    this.selectedCredential[i].value ||
                    this.selectedCredential[i].value === 0 ||
                    i == "roles" ||
                    i == ""
                ) {
                    this.inputsController.inputs[reference].visible = true;
                    if (i == "scopes" || i == "roles") {
                        this.inputsController.inputs[reference].value =
                            this.selectedCredential[i].value.split(", ");
                    } else if (i == "quotaInt") {
                        let quotaInt = this.selectedCredential[i].value;
                        this.inputsController.inputs[reference].value = parseInt(quotaInt);
                    } 
                    else if (i == "expiryDate") {
                            this.inputsController.inputs[reference].disabled = !this.selectedCredential[i].value;
                            this.inputsController.inputs[reference].value = this.selectedCredential[i].value;
                    } else {
                        let value = this.selectedCredential[i].value;
                        this.inputsController.inputs[reference].value = value;
                        if (i == "quota") {
                            this.inputsController.inputs["credentials.quotaPeriod"].visible = true;
                            this.inputsController.inputs["credentials.quotaPeriod"].value =
                                value.split(":")[1];
                        }
                    }
                } else {
                    if (i == "quotaInt") {
                        this.inputsController.inputs[reference].value = 0;
                        this.inputsController.inputs[reference].visible = true;
                    }
                    if (i == "geoTabId") {
                        this.inputsController.inputs[reference].value = "";
                        this.inputsController.inputs[reference].visible = true;
                    }
                    if (i == "quota") {
                        this.inputsController.inputs["credentials.quotaPeriod"].visible = true;
                        this.inputsController.inputs["credentials.quotaPeriod"].value = "DAYS";
                    } else {
                        this.inputsController.inputs[reference].value = "";
                    }
                }
            }
        },
        toggleEditMode() {
            if (this.edit_credential_mode) {
                return this.displayDiscardSaveCredentialsDialog();
            }

            if (this.edit_mode) {
                return this.displayDiscardDialog();
            }
            this.edit_mode = true;

            for (let field of this.credentialsFields) {
                this.inputsController.inputs[field].visible = false;
                this.inputsController.inputs[field].value = "";
            }

            for (let i in this.selectedComponent) {
                if (
                    this.selectedComponent[i].value ||
                    i == "credentials.quotaInt" ||
                    i == "credentials.expiryDate" ||
                    typeof this.selectedComponent[i].value === "boolean"
                ) {
                    this.inputsController.inputs[i].visible = true;
                    switch (i) {
                        case "scopes":
                        case "credentials.roles":
                            this.inputsController.inputs[i].value =
                                this.selectedComponent[i].value.split(", ");
                            break;
                        case "credentials.quotaInt":
                            this.inputsController.inputs[i].value = this.selectedComponent[i].value ?
                                parseInt(this.selectedComponent[i].value) : 0;
                            break;
                        case "credentials.quota":
                            this.inputsController.inputs["credentials.quota"].value =
                                this.selectedComponent[i].value;
                            this.inputsController.inputs["credentials.quotaPeriod"].value =
                                this.selectedComponent[i].value.split(":")[1];
                            this.inputsController.inputs["credentials.quotaPeriod"].visible = true;
                            break;
                        case "credentials.expiryDate": 
                            this.inputsController.inputs[i].disabled = !this.selectedComponent[i].value;
                            this.inputsController.inputs[i].value = this.selectedComponent[i].value;
                            break
                        default:
                            this.inputsController.inputs[i].value = this.selectedComponent[i].value;
                    }
                } else {
                    if (!this.credentials.length) {
                        this.inputsController.inputs[i].value = "";
                        this.inputsController.inputs[i].visible = true;
                        continue;
                    }

                    switch (i) {
                        case "credentials.quotaInt":
                            this.inputsController.inputs[i].visible = true;
                            this.inputsController.inputs[i].value = 0;
                            this.inputsController.inputs["credentials.quotaPeriod"].value = "DAYS";
                            this.inputsController.inputs["credentials.quotaPeriod"].visible = true;
                            break;
                        default:
                            this.inputsController.inputs[i].value = "";
                    }
                }
            }
        },
        setSavedUserAsSelected() {
            for (let i in this.inputsController.inputs) {
                const value = this.inputsController.inputs[i].value

                if (i === "credentials.quotaPeriod" || !this.inputsController.inputs[i].isUserProperty) {
                    continue
                }

                if (value && (i === "scopes" || i === "credentials.roles")) {
                    this.selectedComponent[i].value = value.join(", ")
                    continue
                }

                if (i === "credentials.quota") {
                    this.selectedComponent[i].value =
                        this.inputsController.inputs["credentials.quotaInt"].value +
                        ":" +
                        this.inputsController.inputs["credentials.quotaPeriod"].value;
                    continue
                }

                if (typeof value !== 'string' && !value && i !== 'credentials.quotaInt') {
                    continue
                }

                this.selectedComponent[i].value = value
            }
        },
        setSavedCredentialAsSelected() {
            for (let i in this.selectedCredential) {
                let field = this.selectedCredential[i];
                if (i == "roles") {
                    const value = this.inputsController.inputs[field.referenceToUser].value;
                    field.value =
                        typeof value === "string"
                            ? value
                            : this.inputsController.inputs[field.referenceToUser].value.join(", ");
                } else if (i == "quota") {
                    field.value =
                        this.inputsController.inputs["credentials.quotaInt"].value +
                        ":" +
                        this.inputsController.inputs["credentials.quotaPeriod"].value;
                } else {
                    field.value = this.inputsController.inputs[field.referenceToUser].value;
                }
            }
            if (this.credentials.length > 0) {
                for (let j in this.credentials) {
                    let credential = this.credentials[j];
                    if (
                        credential.username ==
                        this.inputsController.inputs["credentials.username"].value
                    ) {
                        for (let i in this.selectedCredential) {
                            let field = this.selectedCredential[i];

                            if (i == "quota") {
                                credential[i] =
                                    this.inputsController.inputs["credentials.quotaInt"].value +
                                    ":" +
                                    this.inputsController.inputs["credentials.quotaPeriod"].value;
                            } else {
                                credential[i] =
                                    this.inputsController.inputs[field.referenceToUser].value;
                            }
                        }
                    }
                }
            }
        },
        apiInputChange(title, selected, value) {
            if(title === 'credentials.isExpiryDateAvailable') {
                this.inputsController.inputs['credentials.expiryDate'].disabled = !value;
                this.inputsController.inputs['credentials.expiryDate'].value = this.selectedComponent['credentials.expiryDate'].value && value ? this.selectedComponent['credentials.expiryDate'].value : '';
                this.inputsController.inputs['credentials.expiryDate'].error = null;
                this.inputsController.inputs['credentials.expiryDate'].mandatory = value;
            } 
            this.inputsController.inputs[title].selected = selected;
            this.inputsController.inputs[title].value = value;
            this.enableRequest();
        },
        dismissComponentSelection() {
            this.isComponentSelected = false;
            this.isCredentialSelected = false;
            this.credentials = [];
        },
        dismissCredentialSelection() {
            this.isCredentialSelected = false;
        },
        selectCredential(credential) {
            if (this.edit_mode) {
                return this.displayDiscardDialog(this.selectCredential, credential);
            }

            if (this.edit_credential_mode) {
                return this.displayDiscardSaveCredentialsDialog(this.selectCredential, credential);
            }
            this.isCredentialSelected = true;

            for (let i in this.selectedCredential) {
                if (i == "isExpiryDateAvailable") {
                        this.selectedCredential[i].value = !!credential['expiryDate'];
                } else if (this.selectedCredential[i] || credential[i] == 0) {
                    if (credential[i] && i == "roles") {
                        this.selectedCredential[i].value = credential[i].join(", ");
                        this.selectedCredential[i].visible = true;
                    } else {
                        this.selectedCredential[i].value = credential[i] ?? "";
                        this.selectedCredential[i].visible = true;
                    }
                }
            }
            this.goToTopCredentialCard();
        },
        selectComponent(componentData) {
            if (this.edit_mode) {
                return this.displayDiscardDialog(this.selectComponent, componentData);
            }

            if (this.edit_credential_mode) {
                return this.displayDiscardSaveCredentialsDialog(
                    this.selectComponent,
                    componentData
                );
            }

            this.isComponentSelected = true;
            this.isCredentialSelected = false;

            for (let field of this.credentialsFields) {
                if(field === UserFields.CREDENTIALS_EXPIRY_DATE && !componentData.credentials) {
                    this.selectedComponent[field].visible = true;
                } else {
                    this.selectedComponent[field].visible = false;
                    this.selectedComponent[field].value = "";
                }
            }

            this.credentials = [];
            if (componentData["credentials"]) {
                this.credentials = JSON.parse(componentData["credentials"]);
                if (this.credentials && this.credentials.roles) {
                    this.credentials.roles = this.credentials.roles.join(",\r\n");
                }
            }

            for (let componentDataKey in componentData) {
                if (
                    componentDataKey === "credentials" ||
                    !this.selectedComponent[componentDataKey]
                ) {
                    continue;
                }

                switch (componentDataKey) {
                    case UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE:
                        if(!componentData.credentials) {
                            this.selectedComponent[componentDataKey].value =
                                !!componentData[UserFields.CREDENTIALS_EXPIRY_DATE];
                        }
                        break;
                    case UserFields.CREDENTIALS_GEOTABID:
                        if(!componentData.credentials) {
                            this.selectedComponent[componentDataKey].value =
                            componentData[componentDataKey];
                            this.selectedComponent[componentDataKey].visible = true
                        }
                        break;
                    default:
                        this.selectedComponent[componentDataKey].value =
                            componentData[componentDataKey];
                }
            }

            this.goToTopUserCard();
        },
        setButtonLabel(label) {
            this.$emit("setButtonLabel", label);
        },
        async updatePasswordRequest() {
            let bodyParams = {
                isSendClientSecretAsSMS: this.inputsController.inputs.isSendClientSecretAsSMS.value,
                userId: this.selectedComponent.userId.value,
                phone: this.selectedComponent.phone.value,
                updatePassword: true,
            };
            if (this.credentials.length == 0) {
                bodyParams["roles"] = this.selectedComponent["credentials.roles"].value.split(", ");
                bodyParams["quota"] = parseInt(
                    this.selectedComponent["credentials.quotaInt"].value
                );
                bodyParams["quotaPeriod"] =
                    this.selectedComponent["credentials.quota"].value.split(":")[1];
                bodyParams["username"] = this.selectedComponent["credentials.username"].value;
                bodyParams["geoTabId"] = this.selectedComponent["credentials.geoTabId"].value;
            } else {
                bodyParams["roles"] = this.selectedCredential.roles.value.split(", ");
                bodyParams["quota"] = parseInt(this.selectedCredential.quotaInt.value);
                bodyParams["quotaPeriod"] = this.selectedCredential.quota.value.split(":")[1];
                bodyParams["username"] = this.selectedCredential.username.value;
                bodyParams["geoTabId"] = this.selectedCredential.geoTabId.value;
            }

            await this.updateUserOrCredential(bodyParams, false, false, true);
        },
        async updateCredentialsRequest() {
            let bodyParams = {
                userId: this.selectedComponent.userId.value,
                roles: this.inputsController.inputs["credentials.roles"].value,
                quota: parseInt(this.inputsController.inputs["credentials.quotaInt"].value),
                quotaPeriod: this.inputsController.inputs["credentials.quotaPeriod"].value,
                geoTabId: this.inputsController.inputs["credentials.geoTabId"].value,
                isExpiryDateAvailable : this.inputsController.inputs["credentials.isExpiryDateAvailable"].value,
                expiryDate : this.inputsController.inputs["credentials.expiryDate"].value,
                ...(this.selectedCredential['geoTabId'].value && this.inputsController.inputs["credentials.geoTabId"].value !== this.selectedCredential['geoTabId'].value ?
                    {oldGeoTabId: this.selectedCredential['geoTabId'].value} : {} 
                ),
                username: this.inputsController.inputs["credentials.username"].value,
            };
            await this.updateUserOrCredential(bodyParams, false, true, false);
        },
        async saveUserRequest() {
            this.$emit("setLoadingStatus");
            this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.saveUserPath;

            const expiryDate = this.inputsController.inputs['credentials.expiryDate'].value;
            let bodyParams = {
                userId: this.inputsController.inputs.userId.value,
                phone: this.inputsController.inputs.phone.value,
                company: this.inputsController.inputs.company.value,
                address: this.inputsController.inputs.address.value,
                reseller: this.inputsController.inputs.reseller.value,
                date: this.inputsController.inputs.date.value,
                updatePassword: false,
                notes: this.inputsController.inputs.notes.value,
                email: this.inputsController.inputs.email.value,
                externalId: this.inputsController.inputs.externalId.value,
                expiryDate: expiryDate ? new Date(expiryDate).toISOString() : null,
                isExpiryDateAvailable: this.inputsController.inputs['credentials.isExpiryDateAvailable'].value,
            };
            if (this.credentials.length == 0) {
                const credentialsRolesValue =
                    this.inputsController.inputs["credentials.roles"].value;
                bodyParams["roles"] = credentialsRolesValue
                    ? credentialsRolesValue.filter((r) => !!r)
                    : undefined;

                bodyParams["quota"] = parseInt(
                    this.inputsController.inputs["credentials.quotaInt"].value
                );
                bodyParams["quotaPeriod"] =
                    this.inputsController.inputs["credentials.quotaPeriod"].value;

                bodyParams["username"] = this.inputsController.inputs["credentials.username"].value;
                bodyParams["password"] = this.inputsController.inputs["credentials.password"].value;
                if(this.selectedComponent['credentials.geoTabId'].value && this.inputsController.inputs["credentials.geoTabId"].value !== this.selectedComponent['credentials.geoTabId'].value) {
                    bodyParams["oldGeoTabId"] = this.selectedComponent['credentials.geoTabId'].value; 
                }
                bodyParams["geoTabId"] = this.inputsController.inputs["credentials.geoTabId"].value;
            }
            await this.updateUserOrCredential(
                bodyParams,
                true,
                this.credentials.length == 0,
                false
            );
        },
        async updateUserOrCredential(
            bodyParams,
            isUserUpdated,
            isCredentialUpdated,
            isPWDUpdate = false
        ) {
            this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.saveUserPath;

            let responseParsed = {
                error: false,
                errorMessage: "",
                responseTable: {},
                isSelectable: true,
                redirect: false,
            };

            fetch(this.apiEndpoint, {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: this.getSessionToken,
                },
                body: JSON.stringify(bodyParams),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        response.status = 500;
                        response.errorMessage = "Unknown error";
                        return response;
                    }
                })
                .then(async (data) => {
                    if (data) {
                        if (data.statusCode == 500) {
                            responseParsed.status = 500;
                            responseParsed.error = true;
                            responseParsed.errorMessage = JSON.parse(data.body).description;
                        } else if (data.statusCode == 501) {
                            responseParsed.error = true;
                            responseParsed.errorMessage = data.body;
                        } else if (data.statusCode == 401) {
                            responseParsed.error = true;
                            responseParsed.errorMessage = data.body;
                        } else if (data.statusCode == 200) {
                            if (isPWDUpdate) {
                                const body = JSON.parse(data.body);
                                this.passwordUpdated = { ...body, password: body.password.trim() };
                                this.currentDialog = "passwordUpdated";
                                this.dialogs[this.currentDialog].show = true;
                            }
                            if (isUserUpdated) {
                                this.setSavedUserAsSelected();
                            }
                            if (isCredentialUpdated) {
                                this.setSavedCredentialAsSelected();
                            }

                            this.$emit("unsetLoadingStatus");

                            this.edit_mode = false;
                            this.edit_credential_mode = false;
                            await this.executeRequest();
                        } else {
                            responseParsed.error = true;
                            responseParsed.errorMessage = "Unknown error";
                        }
                    }
                })
                .catch((error) => {
                    responseParsed.error = false;
                    responseParsed.status = 500;
                    responseParsed.errorMessage =
                        "There has been a problem with your fetch operation: " +
                        error +
                        ". This error migth be caused because you do not have a valid session active or your IP is being whitelisted.";
                    responseParsed.redirect = true;
                    this.$emit("unsetLoadingStatus");
                });
        },
        createRequestBody() {
            let body = {
                body: this.isNotDisplayUsersWithZeroQuota
                    ? {
                          "credentials.quotaInt": {
                              "$gt": 0,
                          },
                      }
                    : {},
                sort: { email: 1 },
            };

            if (!this.filterValues?.fieldSelector?.value && !this.filterValues?.value?.value) {
                return JSON.stringify(body);
            }

            const fieldSelectorId = UserFieldsOptions.find(
                (o) => o.label === this.filterValues.fieldSelector.value
            ).id;
            const operandTranslation = this.filterValues.operator.value;
            body.body = {
                ...body.body,
                [fieldSelectorId]: {
                    [operandTranslation]: this.filterValues.value.value,
                    ...(operandTranslation === '$regex' ? {"$options": "i"} : {})
                },
            };
            return JSON.stringify(body);
        },
        async executeRequest() {
            this.$emit("setLoadingStatus", true);

            let token = await this.getSessionToken;
            if (!token) {
                token = await this.$store.dispatch("getLoggedInfo");
            }

            let responseParsed = {
                error: false,
                errorMessage: "",
                responseTable: {},
                isSelectable: true,
                redirect: false,
            };

            const body = this.createRequestBody();
            fetch(process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath, {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: body,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        response.status = 500;
                        response.errorMessage = "Unknown error";
                        return response;
                    }
                })
                .then((data) => {
                    if (data) {
                        if (data.statusCode == 500) {
                            responseParsed.status = 500;
                            responseParsed.error = true;
                            responseParsed.errorMessage = JSON.parse(data.body).description;
                        } else if (data.statusCode == 501) {
                            responseParsed.error = true;
                            responseParsed.errorMessage = data.body;
                        } else if (data.statusCode == 200) {
                            let parsedAnswer = JSON.parse(data.body);

                            let tableResponse = [];

                            if (this.apiEndpoint == this.getUserPath) {
                                tableResponse.push(parsedAnswer);
                            } else {
                                tableResponse = parsedAnswer;
                            }

                            responseParsed.responseTable = this.parseResponse(
                                tableResponse,
                                ResponseConfig
                            );
                        } else {
                            responseParsed.error = true;
                            responseParsed.errorMessage = "Unknown error";
                        }
                    }
                    this.$emit("responseReady", responseParsed);
                })
                .catch((error) => {
                    responseParsed.error = false;
                    responseParsed.status = 500;
                    responseParsed.errorMessage =
                        "There has been a problem with your fetch operation: " +
                        error +
                        ". This error migth be caused because you do not have a valid session active or your IP is being whitelisted.";
                    responseParsed.redirect = true;
                    this.$emit("responseReady", responseParsed);
                });
        },
    },
};
</script>

<style scoped>
.sublistSelectedItem {
    text-align: right;
}

.input-container {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    padding-bottom: 2em;
}

.inputs-card {
    margin-left: 1em;
    margin-right: 1em;
    width: 95em;
}

.column_wrapper {
    column-count: 2;
}

.credential-section {
    margin-top: 1em;
}
</style>
