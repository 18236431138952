var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableHeaders)?_c('tr',{ref:"rowStart"},_vm._l((Object.values(_vm.row)),function(tableCell,index_cell){return _c('td',{key:index_cell,class:{ expanded: _vm.isExpanded, selected: _vm.isSelected },on:{"click":function($event){return _vm.rowAction()}}},[_c('div',{staticClass:"dataCell",class:{
                    iconCell:
                        _vm.tableHeaders[index_cell] &&
                        _vm.tableHeaders[index_cell].value == 'classification',
                },style:({
                    height: _vm.isExpanded
                        ? Math.max(Math.round(_vm.longestFieldLength / 35) * 20, 100) + 'px'
                        : '40px',
                })},[(typeof tableCell === 'number')?_c('span',[_vm._v(" "+_vm._s(tableCell)+" ")]):(Array.isArray(tableCell))?_c('span',[(tableCell.join(',\r\n').length > 40)?_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(tableCell.join(",\r\n")))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tableCell.join(",\r\n"))+" ")])]):_c('span',[_vm._v(" "+_vm._s(tableCell.join(",\r\n"))+" ")])],1):(_vm.tableHeaders[index_cell].value == 'credentials.roles' && tableCell.length > 43)?_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(tableCell.split("\\n").join("\n")))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tableCell.split("\\n").join("\n"))+" ")])]):_c('span',[_vm._v(" "+_vm._s(tableCell ? tableCell.split("\\n").join("\n") : "")+" ")])],1)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }