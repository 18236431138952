<template>
    <v-card class="mx-auto tableCard" color="secondary lighten-4" @unselectAll="unselectAll">
        <v-data-table
            ref="dataTable"
            :headers="renderedHeader"
            :items="
                responseTable.tableContent.filter((row) =>
                    hideInternal ? row.company?.toLowerCase() !== 'internal' : true
                )
            "
            :items-per-page="999999"
            :hide-default-footer="true"
            item-key="name"
            fixed-header
            dense
        >
            <template v-slot:item="{ item, index }">
                <TableRowDisplay
                    :key="index"
                    :id="'row_' + index"
                    :row="item"
                    :table-headers="responseTable.tableHeaders"
                    :is-expanded="expandedRow == index"
                    :is-selected="selectedRow == index"
                    :isMobile="isMobile"
                    :index="index"
                    :user-selected="userSelected"
                    @selectElement="selectElement"
                    @rowAction="rowAction"
                    :is-credentials-table="isCredentialsTable"
                >
                </TableRowDisplay>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import TableRowDisplay from "./TableRowDisplay";

export default {
    name: "Table",
    components: { TableRowDisplay },
    props: ["responseTable", "isSelectable", "userSelected", "hideInternal", "isCredentialsTable"],
    created() {
        window.addEventListener("resize", this.screenResized);
    },
    destroyed() {
        window.removeEventListener("resize", this.screenResized);
    },
    beforeMount() {
        this.screenResized({ target: window });
    },
    mounted() {},
    watch: {},
    data() {
        return {
            singleExpand: false,
            isMobile: null,
            expandedRow: -1,
            selectedRow: -1,
        };
    },
    computed: {
        renderedHeader() {
            let renderedHeader = [];
            if (this.isMobile && !this.isExpanded) {
                for (let idx in this.responseTable.tableHeaders) {
                    let header = this.responseTable.tableHeaders[idx];
                    if (header.visibleWhenMobile) {
                        renderedHeader.push(header);
                    }
                }
            } else {
                for (let idx in this.responseTable.tableHeaders) {
                    let header = this.responseTable.tableHeaders[idx];
                    if (!header.notVisibleWhenRow) {
                        renderedHeader.push(header);
                    }
                }
            }

            return renderedHeader;
        },
    },
    methods: {
        unselectAll() {
            if (!this.isSelectable) {
                this.selectedRow = -1;
            }
        },
        screenResized(e) {
            if (e.target.outerWidth <= 760) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        rowAction(index) {
            if (this.isSelectable) {
                this.selectedRow = index;
            } else {
                if (this.expandedRow == index) {
                    this.expandedRow = -1;
                } else {
                    this.expandedRow = index;
                }
            }
        },
        selectElement(index) {
            if (this.isSelectable) {
                for (let element of this.responseTable.tableContent) {
                    if (!this.isCredentialsTable && element.userId == index) {
                        this.$emit("selectElement", element);
                        return;
                    }
                    if (this.isCredentialsTable && element.username == index) {
                        this.$emit("selectElement", element);
                        return;
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.tableCard {
    max-width: 100%;
    padding: 1em;
}

.selected td {
    background: #71c791;
}
</style>
