<template>
  <TableCard v-if="renderedHeader.length>0 && isExpanded && isMobile"
             :row="renderedRow"
             :table-headers="renderedHeader"
             :is-expanded="isExpanded"
             :is-selected="isSelected"
             @rowAction="rowAction"
             :index="row[isCredentialsTable?'username':'userId']"></TableCard>
  <TableRow

      v-else-if="renderedHeader.length>0"
      :expanded="isExpanded"
      :row="renderedRow"
      :table-headers="renderedHeader"
      :is-expanded="isExpanded"
      :is-selected="isSelected"
      :user-selected="userSelected"
      @selectElement="selectElement"
      @rowAction="rowAction"
      :index="row[isCredentialsTable?'username':'userId']">
   </TableRow>

</template>

<script>
import TableCard from "./TableCard";
import TableRow from "./TableRow";
import TableElement from "./mixings/TableElement";

export default {
  props: ['isExpanded', 'isSelected', 'isMobile', 'row', 'tableHeaders','userSelected','isCredentialsTable'],
  mixins: [TableElement],
  name: "TableRowDisplay",
  components: {TableRow, TableCard},
  mounted() {

  },

  methods: {
    selectElement(index) {

      this.$emit('selectElement', index);
    },
  },
  computed: {

    renderedHeader() {


      let renderedHeader = []
      if (this.isMobile && !this.isExpanded) {
        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];
          if (header.visibleWhenMobile) {
            renderedHeader.push(header);
          }
        }
      } else {
        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];
          if (!header.notVisibleWhenRow) {
            renderedHeader.push(header);
          }
        }
      }

      return renderedHeader;
    },
    renderedRow() {

      let renderedRow = [];
      if (this.isMobile && !this.isExpanded) {

        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];
          if (header.visibleWhenMobile) {
            renderedRow.push(header.display ? header.display(this.row[header.value]) : this.row[header.value]);
          }
        }
      } else {

        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];

          if (!header.notVisibleWhenRow) {


            renderedRow.push(header.display ? header.display(this.row[header.value]) : this.row[header.value]);
          }
        }

      }

      return renderedRow;
    }

  }

}
</script>

<style scoped>

</style>
