import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "Company",
            field: "company",
            visibleWhenMobile: true,
            notVisibleWhenRow: false,
        },
        {
            name: "Reseller",
            field: "reseller",
            visibleWhenMobile: true,
            notVisibleWhenRow: false,
        },
        {
            name: "Email",
            field: "email",
            visibleWhenMobile: true,
            notVisibleWhenRow: false,
        },
        {
            name: "User ID",
            field: "userId",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Quota",
            field: "credentials.quota",
            visibleWhenMobile: false,
            notVisibleWhenRow: false,
        },
        {
            name: "Quota Integer",
            field: "credentials.quotaInt",
            visibleWhenMobile: false,
            notVisibleWhenRow: true,
        },
        {
            name: "GeoTab ID",
            field: "credentials.geoTabId",
            visibleWhenMobile: false,
            notVisibleWhenRow: false,
            display: (value) => value && value.length > 10 ? "..." + value.slice(-4) : value
        },
        {
            name: "Date",
            field: "date",
            visibleWhenMobile: true,
            notVisibleWhenRow: false,
        },
        {
            name: "Expiry Date",
            field: "credentials.expiryDate",
            visibleWhenMobile: false,
            notVisibleWhenRow: false,
        },
        {
            name: "Roles",
            field: "credentials.roles",
            visibleWhenMobile: true,
            notVisibleWhenRow: false,
        },
        {
            name: "User ID",
            field: "userId",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Credentials",
            field: "credentials",
            visibleWhenMobile: false,
            notVisibleWhenRow: true,
        },
        {
            name: "Username",
            field: "credentials.username",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Password",
            field: "credentials.password",
            visibleWhenMobile: false,
            notVisibleWhenRow: true,
        },
        {
            name: "Address",
            field: "address",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Phone",
            field: "phone",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Notes",
            field: "notes",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "External Id",
            field: "externalId",
            visibleWhenMobile: true,
            notVisibleWhenRow: true,
        },
        {
            name: "Expiry date available",
            field: "credentials.isExpiryDateAvailable",
            visibleWhenMobile: false,
            notVisibleWhenRow: true,
        },
    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(
            expression,
            json,
            key,
            level,
            result,
            ["roles", "scopes"],
            ["credentials"],
            ["credentials"]
        );
    },
};
