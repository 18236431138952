export const UserFields = {
    EMAIL: "email",
    USER_ID: "userId",
    ADDRESS: "address",
    CREDENTIALS_PASSWORD: "credentials.password",
    CREDENTIALS_QUOTA_INT: "credentials.quotaInt",
    CREDENTIALS_QUOTA: "credentials.quota",
    CREDENTIALS_GEOTABID: "credentials.geoTabId",
    CREDENTIALS_USERNAME: "credentials.username",
    CREDENTIALS_ROLES: "credentials.roles",
    CREDENTIALS_EXPIRY_DATE: "credentials.expiryDate",
    CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE: "credentials.isExpiryDateAvailable",
    COMPANY: "company",
    DATE: "date",
    PHONE: "phone",
    RESELLER: "reseller",
    NOTES: "notes",
    EXTERNAL_ID: "externalId",
};

export const UserFieldsLabel = {
    [UserFields.EMAIL]: "Email",
    [UserFields.USER_ID]: "User ID",
    [UserFields.CREDENTIALS_EXPIRY_DATE]: "Expiry date",
    [UserFields.CREDENTIALS_PASSWORD]: "Password",
    [UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE]: "Expiry date available",
    [UserFields.ADDRESS]: "Address",
    [UserFields.CREDENTIALS_QUOTA_INT]: "Quota",
    [UserFields.CREDENTIALS_QUOTA]: "Quota",
    [UserFields.CREDENTIALS_GEOTABID]: "Geotab ID",
    [UserFields.CREDENTIALS_USERNAME]: "Username",
    [UserFields.CREDENTIALS_ROLES]: "Roles",
    [UserFields.COMPANY]: "Company",
    [UserFields.DATE]: "Date",
    [UserFields.PHONE]: "Phone",
    [UserFields.RESELLER]: "Reseller",
    [UserFields.NOTES]: "Notes",
    [UserFields.EXTERNAL_ID]: "External Id",
};

export const UserFieldsOptions = [
    {
        id: UserFields.EMAIL,
        label: UserFieldsLabel[UserFields.EMAIL],
    },
    {
        id: UserFields.USER_ID,
        label: UserFieldsLabel[UserFields.USER_ID],
    },
    {
        id: UserFields.CREDENTIALS_EXPIRY_DATE,
        label: UserFieldsLabel[UserFields.CREDENTIALS_EXPIRY_DATE],
    },
    {
        id: UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE,
        label: UserFieldsLabel[UserFields.CREDENTIALS_IS_EXPIRY_DATE_AVAILABLE],
    },
    {
        id: UserFields.CREDENTIALS_PASSWORD,
        label: UserFieldsLabel[UserFields.CREDENTIALS_PASSWORD],
    },
    {
        id: UserFields.ADDRESS,
        label: UserFieldsLabel[UserFields.ADDRESS],
    },
    {
        id: UserFields.CREDENTIALS_QUOTA_INT,
        label: UserFieldsLabel[UserFields.CREDENTIALS_QUOTA_INT],
    },
    {
        id: UserFields.CREDENTIALS_QUOTA,
        label: UserFieldsLabel[UserFields.CREDENTIALS_QUOTA],
    },
    {
        id: UserFields.CREDENTIALS_GEOTABID,
        label: UserFieldsLabel[UserFields.CREDENTIALS_GEOTABID],
    },
    {
        id: UserFields.CREDENTIALS_USERNAME,
        label: UserFieldsLabel[UserFields.CREDENTIALS_USERNAME],
    },
    {
        id: UserFields.CREDENTIALS_ROLES,
        label: UserFieldsLabel[UserFields.CREDENTIALS_ROLES],
    },
    {
        id: UserFields.COMPANY,
        label: UserFieldsLabel[UserFields.COMPANY],
    },
    {
        id: UserFields.DATE,
        label: UserFieldsLabel[UserFields.DATE],
    },
    {
        id: UserFields.PHONE,
        label: UserFieldsLabel[UserFields.PHONE],
    },
    {
        id: UserFields.RESELLER,
        label: UserFieldsLabel[UserFields.RESELLER],
    },
    {
        id: UserFields.NOTES,
        label: UserFieldsLabel[UserFields.NOTES],
    },
    {
        id: UserFields.EXTERNAL_ID,
        label: UserFieldsLabel[UserFields.EXTERNAL_ID],
    },
];
