<template>
  <v-app-bar elevation="3" color="primary" dense fixed app height="50px">
    <v-toolbar-items><img class="logo" src="../assets/logo.png"/>

    </v-toolbar-items>
    <v-toolbar-title class="title white--text"><h2>MacsDS User Management</h2></v-toolbar-title>
  </v-app-bar>
</template>
<script>

export default {
  name: 'Header',

}

</script>
<style scoped>
.v-toolbar__items {
  display: block;
}

.logo {
  padding-top: 1px;
  width: 5em;
  border-radius: 7px;
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
}

.title {
  padding: 3em;
}
</style>
