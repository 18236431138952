import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
    ],
    getFieldFromPath(expression, json, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, level, result);
    }
    ,
}

