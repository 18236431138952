import convertDateToText from "@/utilities/convertDateToText";

export default function getTomorrowDate() {
    const today = new Date();

    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return convertDateToText(tomorrow);
}
