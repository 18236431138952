export default function convertDateToText(date) {
    const inputDate = new Date(date);
    let day, month, year;

    day = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    day = day.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
}
