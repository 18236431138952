import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "Email",
            field: "email",
            visibleWhenMobile: true,
            notVisibleWhenRow:false,
        },
        {
            name: "User ID",
            field: "userId",
            visibleWhenMobile: true,
            notVisibleWhenRow:false,
        },
        {
            name: "Company",
            field: "company",
            visibleWhenMobile: true,
            notVisibleWhenRow:false,
        },
        {
            name: "Address",
            field: "address",
            visibleWhenMobile:true,
            notVisibleWhenRow:false,
        },
        {
            name: "Phone",
            field: "phone",
            visibleWhenMobile:true,
            notVisibleWhenRow:false,
        },
        {
            name: "Reseller",
            field: "reseller",
            visibleWhenMobile:true,
            notVisibleWhenRow:false,
        },
        {
            name: "Notes",
            field: "notes",
            visibleWhenMobile:true,
            notVisibleWhenRow:false,

        },
        {
            name: "External Id",
            field: "externalId",
            visibleWhenMobile:true,
            notVisibleWhenRow:false,

        },
    ],
    getFieldFromPath(expression, json, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, level, result,[], [],[],[]);
    }
    ,
}

