<template>
    <v-menu
        ref="menuDate"
        v-model="isDatePickerOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="fieldValue"
                @input="handelChangeFieldValue"
                v-model="fieldValue"
                :label="label + '' + (isMandatory ? '' : ' (Optional)')"
                hint="YYYY-MM-DD format"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="isDisabled"
                :error="!!error"
                :error-messages="error"
                :rules="rules"
            />
        </template>
        <v-date-picker
            :value="datePickerValue"
            @input="handelChangeDatePickerValue"
            v-model="datePickerValue"
            no-title
            :min="minimumPossibleDate"
        />
    </v-menu>
</template>

<script>
import checkIfStringIsDateValue from "@/utilities/сheckIfStringIsDateValue";

export default {
    name: "date-input",
    props: {
        defaultValue: String,
        isDatePickerOpenDefaultValue: Boolean,
        label: String,
        isMandatory: Boolean,
        isDisabled: Boolean,
        error: String,
        minimumPossibleDate: String,
        rules: Array
    },
    data() {
        return {
            isDatePickerOpen: this.isDatePickerOpenDefaultValue,
            fieldValue: this.defaultValue,
            datePickerValue: this.defaultValue,
        };
    },
    watch: {
        defaultValue(value) {
            this.fieldValue = value;
            this.datePickerValue = value;
        },
        fieldValue(value) {
            this.$emit("handelChange", value);
        },
    },
    methods: {
        handelChangeFieldValue(value) {
            this.fieldValue = value;

            const isDate = checkIfStringIsDateValue(value);
            this.datePickerValue = isDate ? value : null;
        },
        handelChangeDatePickerValue(value) {
            this.datePickerValue = value;
            this.fieldValue = value;
        },
    },
};
</script>

<style scoped></style>
