<template>
    <v-container class="dashboard">
        <v-card elevation="2"> </v-card>

        <v-tabs v-model="tabIndex" fixed-tabs background-color="primary" dark>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="tab in tabs" :key="tab">
                {{ tab }}
            </v-tab>
        </v-tabs>

        <v-card elevation="2">
            <v-form ref="form" @submit.prevent>
                <FieldsComponentsRenderer
                    :actionType="currentAction"
                    :dismissal-request="dismissalRequest"
                    ref="paramHolder"
                    @enableRequest="enableRequest"
                    @responseReady="responseReady"
                    @setButtonLabel="setButtonLabel"
                    @setLoadingStatus="setLoadingStatus"
                    @unsetLoadingStatus="unsetLoadingStatus"
                    @changeAction="changeAction"
                    @userSelected="userSelected"
                    @hideInternal="hideInternalRecords"
                    :isDemo="demo"
                >
                </FieldsComponentsRenderer>

                <v-container fluid>
                    <v-row>
                        <v-col cols="8"></v-col>
                        <v-col cols="4">
                            <div class="button-container">
                                <v-btn
                                    ref="requestButton"
                                    color="primary"
                                    @click="executeRequest"
                                    :disabled="!buttonVisible"
                                    class="form-button"
                                >
                                    <span class="mdi mdi-eye"></span>
                                    {{ apiTabConfiguration[currentAction].buttonLabel }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row ref="loading">
                        <v-col cols="5"></v-col>
                        <v-col cols="3">
                            <v-progress-circular
                                v-if="loading"
                                :size="100"
                                :width="5"
                                indeterminate
                                color="primary"
                            >
                            </v-progress-circular>
                            <v-alert v-else-if="error" type="error">
                                <span v-if="!errorMessage"> Error sending the request!</span>
                                <span v-else> {{ errorMessage }}</span>
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="table-container">
                                <Table
                                    v-if="showTable"
                                    :response-table="responseTable"
                                    :is-selectable="
                                        apiTabConfiguration[currentAction].apiTables.isSelectable
                                    "
                                    :user-selected="selectedUser"
                                    :hide-internal="hideInternal"
                                    @selectElement="selectElement"
                                    :is-credentials-table="false"
                                ></Table>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import FieldsComponentsRenderer from "./FieldsComponentRenderer";
import Table from "../Table/Table.vue";
import { mapGetters } from "vuex";
import { Auth } from "aws-amplify";

export default {
    name: "AppContent",
    props: {
        actionType: String,
    },
    watch: {
        tabIndex(value) {
            this.currentAction = this.tabs[value];
        },
    },
    components: {
        FieldsComponentsRenderer,
        Table,
    },
    async beforeMount() {
        const isLoggedResult = await this.$store.dispatch("getLoggedInfo");
        if (isLoggedResult == -1) {
            await this.$router.push({ name: "Login" });
        }
    },
    mounted() {
        this.currentAction = this.actionType;

        if (!this.currentAction) {
            this.currentAction = "List Users";
        }
    },
    data() {
        return {
            tabIndex: "0",
            selectedUser: "",
            currentAction: "List Users",
            showTable: false,
            error: false,
            errorMessage: "",
            loading: false,
            requestIsEnbled: false,
            tabs: ["List Users", "Add User"],
            hideInternal: true,
            dismissalRequest: new Date(),
            apiTabConfiguration: {
                "Add User": {
                    apiTables: {
                        isSelectable: false,
                    },
                    buttonLabel: "Create User",
                    apiBanner: {
                        show: true,
                        text: "Work in progress! This tab is not fully functional",
                    },
                },
                "List Users": {
                    apiTables: {
                        isSelectable: true,
                    },
                    buttonLabel: "List Users",
                    apiBanner: {
                        show: true,
                        text: "Work in progress! This tab is not fully functional",
                    },
                },
            },
            responseTable: {
                tableHeaders: [],
                tableContent: [],
            },
            demo: false,
        };
    },
    computed: {
        ...mapGetters(["getLoggedUser", "getLoginStatus", "getSessionToken"]),
        buttonVisible() {
            return this.demo || this.requestIsEnbled;
        },
    },
    methods: {
        handleKeyup() {
            console.log("here")
        },
        hideInternalRecords(value) {
            this.hideInternal = value;
        },
        userSelected(user) {
            this.dismissalRequest = new Date();

            this.selectedUser = user;
        },
        setButtonLabel(label) {
            this.apiTabConfiguration[this.currentAction].buttonLabel = label;
        },
        selectElement(elementData) {
            this.$refs.paramHolder.selectComponent(elementData);
        },
        changeAction(action) {
            this.tabIndex = this.tabs.indexOf(action);
        },
        unsetLoadingStatus() {
            this.loading = false;
        },
        setLoadingStatus() {
            this.showTable = false;
            this.loading = true;
        },
        responseReady(response) {
            this.loading = false;
            if (response.error) {
                this.showTable = false;
                this.error = true;
                this.errorMessage = response.errorMessage;
                if (response.redirect) {
                    this.$router.push({ name: "Login" });
                }
            } else if (response.hideTable) {
                this.error = false;
                if (this.currentAction == "List Users") {
                    this.showTable = false;
                    this.responseTable = response.responseTable;
                }
            } else {
                this.error = false;
                if (this.currentAction == "List Users") {
                    this.showTable = true;
                    this.responseTable = response.responseTable;
                }
                this.apiTabConfiguration[this.currentAction].apiTables.isSelectable =
                    response.isSelectable;
            }
        },
        enableRequest(value) {
            this.requestIsEnbled = value;
        },
        async executeRequest() {
            Auth.currentSession().catch(() => {
                this.$router.push({ name: "Login" });
            });

            if (this.requestIsEnbled || this.demo) {
                this.showTable = false;
                this.loading = true;
                this.error = false;
                this.errorMessage = "";
                this.$refs.paramHolder.executeRequest();
            }
        },
    },
};
</script>
<style scoped>
.button-container {
    text-align: right;
}

.form-button {
    margin: 1em;
}

.request-button span {
    padding: 10px;
}

.dashboard {
    margin-top: 1em;
    margin-bottom: 5em;
    max-width: 100em;
}
</style>
