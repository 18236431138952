import checkIfStringIsDateValue from "@/utilities/сheckIfStringIsDateValue";
import getTomorrowDate from "@/utilities/getTomorrowDate";
import { isValidPhoneNumber } from "libphonenumber-js";

export default {
    name: "InputComponent",
    props: ["isDemo"],

    mounted() {
        this.enableRequest();
    },
    watch: {
        "inputsController.inputs.phone.value"(value) {
            if (!value) {
                this.inputsController.inputs.phone.error = null;
                return;
            }

            this.inputsController.inputs.phone.error = isValidPhoneNumber(value)
                ? null
                : "The phone is not valid";
        },
        "inputsController.inputs.expiryDate.value"(value) {
            if (!value) {
                return (this.inputsController.inputs.expiryDate.error = null);
            }

            if (!checkIfStringIsDateValue(value)) {
                return (this.inputsController.inputs.expiryDate.error = "Date in invalid format");
            }

            const enteredDate = new Date(value);
            const minimumAvailableDate = new Date(getTomorrowDate());

            if (enteredDate >= minimumAvailableDate) {
                return (this.inputsController.inputs.expiryDate.error = null);
            }

            this.inputsController.inputs.expiryDate.error =
                "The date must not be earlier than tomorrow";
        },
        apiVersionPath(value) {
            this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + value;
        },
    },
    methods: {
        isMandatoryFieldFilled(element) {
            if (element.type != "number_input" && !element.value) {
                return false;
            } else if (element.type == "multiselect-no-input" && element.value.length == 0) {
                return false;
            } else if (
                element.type == "number_input" &&
                (parseInt(element.value) < 0 ||
                    parseInt(element.value) == null ||
                    isNaN(parseInt(element.value)))
            ) {
                return false;
            }
            return true;
        },
        requestEnablingLogic() {
            const inputs = { ...this.inputsController.inputs, ...this.filterValues };

            for (let i in inputs) {
                let input = inputs[i];
                let isMandatory = input.mandatory;
                let selected = input.selected;
                let isUserProperty = input.isUserProperty;

                if (isMandatory && !selected && !isUserProperty) {
                    let fieldFilled = this.isMandatoryFieldFilled(input);
                    if (!fieldFilled) {
                        return false;
                    }
                }
            }

            return true;
        },
        enableRequest(extraField = true) {
            this.$emit("enableRequest", extraField && this.requestEnablingLogic());
        },
        parseResponse(responseBody, responseConfig) {
            let responseTable = {
                tableHeaders: [],
                tableContent: [],
            };
            let headerCounter = new Array(responseConfig.headers.length).fill(0);
            for (let idx in responseBody) {
                let row = {};
                let cont = 0;
                for (let i in responseConfig.headers) {
                    let cellValue = responseConfig.getFieldFromPath(
                        responseConfig.headers[i].field,
                        responseBody[idx],
                        "",
                        0,
                        ""
                    );
                    if (cellValue && cellValue.length > 0 && cellValue != null) {
                        headerCounter[cont]++;
                        responseTable.tableHeaders[cont] = {
                            text: responseConfig.headers[i].name,
                            value: responseConfig.headers[i].field,
                            visibleWhenMobile: responseConfig.headers[i].visibleWhenMobile,
                            notVisibleWhenRow: responseConfig.headers[i].notVisibleWhenRow,
                            display: responseConfig.headers[i].display,
                        };
                    }
                    row[responseConfig.headers[i].field] =
                        responseConfig.headers[i].field == "date" || responseConfig.headers[i].field === "credentials.expiryDate"
                            ? cellValue.split("T")[0]
                            : cellValue;
                    cont++;
                }
                responseTable.tableContent.push(row);
            }
            return responseTable
        },
    },
};
