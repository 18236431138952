export default {
    getFieldFromPath(
        expression,
        json,
        key,
        level,
        result,
        fieldsToConcatenate = [],
        fieldsToTakeIndex = [],
        fieldstoTakeObject = []
    ) {
        if (!json) {
            return "";
        }

        if (fieldstoTakeObject.includes(expression) && Array.isArray(json) && json.length > 1) {
            result = JSON.stringify(json);
        } else {
            if (typeof json != "object") {
                result += " " + String(json).trim();
            } else if (
                typeof json == "object" &&
                (fieldsToConcatenate.includes(key) || fieldsToTakeIndex.includes(key))
            ) {
                if (fieldsToConcatenate.includes(key)) {
                    result += " " + String(json.join(", ")).trim();
                } else if (
                    fieldsToTakeIndex.includes(key) &&
                    Array.isArray(json) &&
                    json.length == 1
                ) {
                    let expressionItems = expression.split("+");
                    for (let i in expressionItems) {
                        let items = expressionItems[i].split(".");
                        for (let j in json[0]) {
                            if (j == items[level]) {
                                result = this.getFieldFromPath(
                                    expressionItems[i],
                                    json[0][j],
                                    j,
                                    level + 1,
                                    result,
                                    fieldsToConcatenate,
                                    fieldsToTakeIndex,
                                    fieldstoTakeObject
                                ).trim();
                            }
                        }
                    }
                }
            } else {
                let expressionItems = expression.split("+");
                for (let i in expressionItems) {
                    let items = expressionItems[i].split(".");
                    for (let j in json) {
                        if (j == items[level]) {
                            result = this.getFieldFromPath(
                                expressionItems[i],
                                json[j],
                                j,
                                level + 1,
                                result,
                                fieldsToConcatenate,
                                fieldsToTakeIndex,
                                fieldstoTakeObject
                            ).trim();
                        }
                    }
                }
            }
        }

        return result;
    },
};
