<template>
    <v-col
        :class="[{ 'checkbox-box': type === 'checkbox' }, 'api-input']"
        :cols="title == 'vin' ? colSize + 1 : colSize"
        v-if="visible && allowedVersions.includes(version)"
    >
    <v-form ref="form" v-model="formValid" @submit.prevent>
        <v-text-field
            v-if="type == 'input'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            @keyup.enter="onHandleEnter"
            :disabled="disabled"
            autocomplete
            :rules="rules"
            :error="!!error"
            validate-on="input"
            :error-messages="error"
        ></v-text-field>
        <v-text-field
            v-if="type == 'password'"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :type="!showPassword ? 'password' : 'text'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShow"
            readonly
        >
        </v-text-field>
        <v-text-field
            v-if="type == 'number_input'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :disabled="disabled"
            autocomplete
            :rules="rules"
            type="number"
        ></v-text-field>
        <DateInput
            v-if="type === 'date'"
            :defaultValue="fieldValue"
            :isDatePickerOpenDefaultValue="dateMenu"
            :label="label"
            :isMandatory="mandatory"
            :isDisabled="disabled"
            :error="error"
            :rules="rules"
            :minimumPossibleDate="minimumPossibleDate"
            @handelChange="handelChange"
        />
        <v-select
            v-if="type == 'select'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :items="itemsSorted"
            :disabled="disabled"
            :rules="rules"
        ></v-select>
        <v-combobox
            v-if="type == 'dropdown'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :items="itemsSorted"
            :menu-props="{ minWidth: '250' }"
            :disabled="disabled"
            :rules="rules"
        ></v-combobox>
        <v-combobox
            v-if="type == 'dropdown_noKeyUpUpdate'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :items="itemsSorted"
            :menu-props="{ minWidth: '250' }"
            :disabled="disabled"
            :rules="rules"
        ></v-combobox>
        <v-combobox
            v-if="type == 'multidropdown'"
            class="text-capitalize"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            v-model="fieldValue"
            :items="itemsSorted"
            :disabled="disabled"
            :rules="rules"
        ></v-combobox>
        <v-select
            class="text-capitalize"
            v-if="type == 'multiselect-no-input'"
            v-model="arrayValue"
            :items="itemsSorted"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            attach
            chips
            multiple
            :rules="rules"
        >
            <template v-slot:selection="{ item }">
                <v-chip color="secondary-darken-1">{{ item }}</v-chip>
            </template>
        </v-select>

        <v-checkbox
            v-if="type === 'checkbox'"
            v-model="fieldValue"
            color="primary"
            :label="label"
            :disabled="disabled"
            :rules="rules"
        />

        <v-combobox
            class="text-capitalize"
            v-if="type == 'multiselect'"
            v-model="fieldValue"
            :items="items"
            :label="label + '' + (mandatory ? '' : ' (Optional)')"
            small-chips
            multiple
            :disabled="disabled"
            :rules="rules"
        >
            <template #selection="{ item }">
                <v-chip color="secondary-darken-1">{{ item }}</v-chip>
            </template>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Press <kbd>enter</kbd> to add a new one
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-combobox>
    </v-form>
    </v-col>
</template>
<script>
import DateInput from "@/components/Layout/apiInput/components/DateInput";

export default {
    name: "api-input",
    components: { DateInput },
    props: [
        "title",
        "type",
        "items",
        "mandatory",
        "defaultValue",
        "version",
        "allowedVersions",
        "visible",
        "colSize",
        "label",
        "disabled",
        "value",
        "minimumPossibleDate",
        "error",
        "rules",
        "validateOnMount"
    ],
    mounted() {
        if(this.$refs.form && this.validateOnMount) {
            this.$refs.form.validate()
        }
    },
    data() {
        return {
            formValid: false,
            fieldValue: this.type === 'date' ? this.defaultValue?.split("T")[0] : this.defaultValue,
            arrayValue: this.defaultValue,
            dateMenu: false,
            showPassword: false,
        };
    },
    computed: {
        itemsSorted() {
            return [...new Set(this.items)].sort();
        },
        buttonLabel() {
            return this.showPassword ? "Hide" : "Show";
        },
    },
    watch: {
        defaultValue(value) {
            this.fieldValue = value;
        },
        fieldValue(value) {
            this.handelChange(value);
        },
        arrayValue(value) {
            this.handelChange(value);
        },
        value(value) {
            this.fieldValue = value;
        },
    },
    methods: {
        onHandleEnter() {
            this.$emit('onKeyDownEnter')
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        handelChange(value) {
            if (this.value === value) {
                return;
            }

            this.$emit(
                "apiInputChange",
                this.title,
                !!value && value !== "",
                this.type === "number_input" ? parseInt(value) : value
            );
        },
    },
};
</script>

<style scoped>
.api-input {
    margin: 0px;
}

.checkbox-box {
    padding-top: 0;
    padding-bottom: 16px;
}
</style>
