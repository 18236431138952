<template>
    <div>
        <v-row>
            <ApiInput
                v-for="value in inputs"
                :key="value.name"
                :title="value.name"
                :label="value.label"
                :type="value.type"
                :default-value="value.value"
                :items="value.items"
                :mandatory="value.mandatory"
                version="V1"
                :allowed-versions="value.versions"
                :visible="value.visible"
                :colSize="value.colSize ? value.colSize : 2"
                :disabled="value.disabled"
                :value="value.value"
                @apiInputChange="handelChange"
                @onKeyDownEnter="onHandleEnter"
            />
        </v-row>
        <div class="row justify-center">
            <v-btn color="primary" @click="clearInput" :disabled="isInputsEmpty">Clear</v-btn>
        </div>
    </div>
</template>

<script>
import ApiInput from "@/components/Layout/apiInput/ApiInput";
import { UserFields, UserFieldsLabel } from "@/constants";

export default {
    name: "FilterInputs",
    props: {},
    components: {
        ApiInput,
    },
    data() {
        return {
            operandOptions: {
                [UserFieldsLabel[UserFields.CREDENTIALS_QUOTA_INT]]: [
                    {
                        text: "=",
                        value: "$eq"
                    },
                    {
                        text: "<",
                        value: "$lt"
                    },
                    {
                        text: "<=",
                        value: "$lte"
                    },
                    {
                        text: ">=",
                        value: "$gte"
                    },
                    {
                        text: ">",
                        value: "$gt"
                    },
                ],
                [UserFieldsLabel[UserFields.EMAIL]]: [
                    {
                        text: "=",
                        value: "$eq"
                    },                    
                    {
                        text: "like",
                        value: "$regex"
                    },       
                ],
                [UserFieldsLabel[UserFields.USER_ID]]: [
                    {
                        text: "=",
                        value: "$eq"
                    },                    
                    {
                        text: "like",
                        value: "$regex"
                    },      
                ],
                [UserFieldsLabel[UserFields.CREDENTIALS_USERNAME]]: [
                    {
                        text: "=",
                        value: "$eq"
                    },                    
                    {
                        text: "like",
                        value: "$regex"
                    },      
                ]
            },
            inputs: {
                fieldSelector: {
                    name: "fieldSelector",
                    label: "Field selector",
                    selected: false,
                    value: "",
                    type: "select",
                    items: [
                        UserFieldsLabel[UserFields.USER_ID],
                        UserFieldsLabel[UserFields.EMAIL],
                        UserFieldsLabel[UserFields.CREDENTIALS_USERNAME],
                        UserFieldsLabel[UserFields.CREDENTIALS_QUOTA_INT],
                    ],
                    mandatory: true,
                    versions: ["V1"],
                    visible: true,
                    disabled: false,
                    colSize: 4,
                    isUserProperty: false,
                    usedWithRegEx: false,
                },
                operator: {
                    name: "operator",
                    label: "Operators",
                    selected: false,
                    value: "",
                    type: "select",
                    items: [],
                    mandatory: true,
                    versions: ["V1"],
                    visible: true,
                    disabled: false,
                    colSize: 4,
                    isUserProperty: false,
                    usedWithRegEx: false,
                },
                value: {
                    name: "value",
                    label: "Value",
                    selected: false,
                    value: "",
                    type: "input",
                    items: [],
                    mandatory: true,
                    versions: ["V1"],
                    visible: true,
                    disabled: false,
                    colSize: 4,
                    isUserProperty: false,
                    usedWithRegEx: false,
                },
            },
        };
    },
    computed: {
        isInputsEmpty() {
            return !Object.values(this.inputs).find((i) => i.value);
        },
    },
    methods: {
        handelChange(title, selected, value) {
            this.inputs[title].selected = selected;
            if(title === 'fieldSelector') {
                this.inputs.operator.items = this.operandOptions[value];
            }
            this.inputs[title].value = value;

            this.$emit("handelFilterValuer", this.inputs);
        },
        clearInput() {
            for (let key in this.inputs) {
                if (!Object.prototype.hasOwnProperty.call(this.inputs, key)) {
                    return;
                }

                this.inputs[key].value = "";
            }

            this.$emit("handelClearInput");
            this.$emit("handelFilterValuer", this.inputs);
        },
        onHandleEnter() {
            this.$emit('onFilter')
        }
    },
    mounted() {
        this.$emit("handelFilterValuer", this.inputs);
    },
};
</script>

<style scoped></style>
